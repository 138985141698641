export const PendingRequestcolumns = [
  { id: "bookingwith", label: "Booking with", align: "center", minWidth: 170 },
  { id: "date", label: "Date", align: "center", minWidth: 170 },
  {
    id: "startTime",
    label: "Start Time",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "duration",
    label: "Duration",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "center",
  },
  {
    id: "actions",
    label: "Action",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "options",
    label: "Options",
    minWidth: 210,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];
export const Historycolumns = [
  { id: "bookingwith", label: "Booking with", align: "center", minWidth: 170 },
  { id: "date", label: "Date", align: "center", minWidth: 170 },
  {
    id: "startTime",
    label: "Start Time",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "duration",
    label: "Duration",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "center",
  },
  {
    id: "historyActions",
    label: "Action",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];
export const Instructorcolumns = [
  { id: "name", label: "Name", align: "center", minWidth: 170 },
  { id: "address", label: "Address", align: "center", minWidth: 170 },
  {
    id: "gender",
    label: "Gender",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "center",
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export const Vehiclescolumns = [
  {
    id: "dateOfFirstRegistration",
    label: "Date of registration",
    align: "center",
    minWidth: 170,
  },
  { id: "brand", label: "Brand", align: "center", minWidth: 170 },
  {
    id: "model",
    label: "Modal",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "color",
    label: "Color",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "numberPlate",
    label: "Number Plate",
    minWidth: 170,
    align: "center",
  },
  {
    id: "gearBox",
    label: "Gearbox",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Vaction",
    label: "Actions",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export const DsVehiclescolumns = [
  {
    id: "dateOfFirstRegistration",
    label: "Date of registration",
    align: "center",
    minWidth: 170,
  },
  { id: "brand", label: "Brand", align: "center", minWidth: 170 },
  {
    id: "model",
    label: "Modal",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "color",
    label: "Color",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "numberPlate",
    label: "Number Plate",
    minWidth: 170,
    align: "center",
  },
  {
    id: "gearBox",
    label: "Gearbox",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export const FindInstructorcolumns = [
  { id: "photo", label: "Picture", align: "center", minWidth: 100 },
  { id: "name", label: "Instructor Name", align: "center", minWidth: 170 },
  { id: "position", label: "Position", align: "center", minWidth: 170 },
  { id: "role", label: "Role", align: "center", minWidth: 170 },
  {
    id: "reservations",
    label: "Actions",
    minWidth: 200,
    align: "center",
  },
  // {
  //   id: "profile",
  //   label: "Profile",
  //   minWidth: 170,
  //   align: "center",
  // },
  // {
  //   id: "chat",
  //   label: "Chat",
  //   minWidth: 170,
  //   align: "center",
  // },
];
