import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // fallbackLng: ["fr", "en"],
    // we init with resources

    resources: {
      en: {
        translations: {
          Name: "Name",
          "Instructor Name": "Instructor Name",
          Picture: "Picture",
          "Start Time": "Start Time",
          "Picked DateTime": "Picked DateTime",
          "Start Hours": "Start Hours",
          "Pick a Time": "Pick a Time",
          Modal: "Modal",
          "Date of registration": "Date of registration",
          Unavailability: "Unavailability",
          Disputes: "Disputes",
          yes: "yes",
          "Pending Requests": "Pending Requests",
          "My Calendar": "My Calendar",
          "Pending request": "Pending Request",
          "pending request": "Pending Request",
          "pending Request": "Pending Request",
          "Pending Request": "Pending Request",
          "Instructor deleted successfully": "Instructor deleted successfully",
          "test data for heading": "test data for heading",
          "test data for headingtest data for heading": "test data for headingtest data for heading",
          "Expiry Date": "Expiry Date",
          "Issueing Authority": "Issueing Authority",
          "Authorization Date": "Authorization Date",
          "Exercise Authorization": "Exercise Authorization",
          "Gear Box": "Gear Box",
          "Vehicle Detail": "Vehicle Detail",
          "Obtain Date": "Obtain Date",
          "Delivery Place": "Delivery Place",
          "License Number": "License Number",
          "Driving License": "Driving License",
          "invalid date": "invalid date",
          "Minimum 3 rows": "Minimum 3 rows",
          Rankings: "Rankings",
          Apply: "Apply",
          filter: "Filter",
          "Instructure On Map": "Instructor On Map",
          "Instructor On Map": "Instructor On Map",
          "Find Instructure": "Find Instructure",
          "Find Instructor": "Find Instructure",
          Chat: "Chat",
          "Can only select your bookings": "You can only select your own bookings",
          "Cannot Book after time": "Cannot Book after time",
          "No connection to server yet.": "No connection to server yet.",
          "can not select past event": "can not select past event",
          "can not select past time": "can not select past time",
          "Vehicle added scuccessfully": "Vehicle added scuccessfully",
          "Vehicle deleted scuccessfully": "Vehicle deleted scuccessfully",
          "Vehicle detail updated sucessfully": "Vehicle detail updated sucessfully",
          "Password updated Successfully": "Password updated Successfully",
          "New password does not match Confirm password": "New password does not match Confirm password",
          "Cannot accept empty fields": "Cannot accept empty fields",
          "Field cannot be empty": "Field cannot be empty",
          "Dispute is created sucessfully": "Dispute is created sucessfully",
          "Status is updated successful": "Status is updated successful",
          "Description cannot be empty": "Description cannot be empty",
          "Request is created for profile update": "Request is created for profile update",
          "Image not Uploaded": "Image not Uploaded",
          "Something Went Wrong": "Something Went Wrong",
          "Instrcutor added successfully": "Instrcutor added successfully",
          reservations: "reservations",
          Reservations: "Reservations",
          "Email or Password is not correct": "Email or Password is not correct",
          position: "position",
          name: "name",
          historyActions: "historyActions",
          Vaction: "Vaction",
          Options: "Options",
          options: "options",
          "Student Registered Successfully": "Student Registered Successfully",
          "Not Registered....Error Occured": "Not Registered....Error Occured",
          "Rental Car Registered Successfully": "Rental Car Registered Successfully",
          "Instructor Registered Successfully": "Instructor Registered Successfully",
          "Not Registered....Somethong went wrong": "Not Registered....Somethong went wrong",
          "Driving School Registered Successfully": "Driving School Registered Successfully",
          "My Profile": "My Profile",
          Reset: "Reset",
          "No data found": "No data found",
          "Enter value": "Enter value",
          "No value found": "No value found",
          "Availability Auto": "Availability Auto",
          "Availablity Manual": "Availablity Manual",
          UnAvailability: "UnAvailability",
          Personal: "Personal",
          "Add Availibity": "Add Availibity",
          Week: "Week",
          "Profile Update Request": "Profile Update Request",
          "Select the options": "Select the options",
          "Reason-Information to update": "Reason-Information to update",
          "Delete Availability": "Delete Availability",
          "Add Event": "Add Event",
          "Meetup point": "Meetup point",
          "Meetup points": "Meetup points",
          Delete: "Delete",
          "Serial Number": "Serial Number",
          "Business Store": "Business Store",
          "Registration Date": "Registration Date",
          "Update Profile": "Update Profile",
          "Insurance Details": "Insurance Details",
          No: "No",
          Yes: "Yes",
          "Would like to delete your availability?": "Would like to delete your availability?",
          Description: "Description",
          Confirm: "Confirm",
          Cancel: "Cancel",
          "End Hours": "End Hours",
          "Start Minutes": "Start Minutes",
          "End Minutes": "End Minutes",
          "Vehicle Select": "Vehicle Select",
          "Select Vehicle": "Select Vehicle",
          "Select Instructor": "Select Instructor",
          "Add Personal Event": "Add Personal Event",
          "Add Unavailability": "Add Unavailability",
          "Add Availability": "Add Availability",
          Search: "Search",
          "Enter username": "Enter username",
          "User Info": "User Info",
          "Add booking date": "Add booking date",
          "Select booking time": "Select booking time",
          "Booking Time": "Booking Time",
          "Enter duration of booking": "Enter duration of booking",
          Duration: "Duration",
          Vechicle: "Vechicle",
          "Select vechicle type": "Select vechicle type",
          "Enter Meet-UP point": "Enter Meet-UP point",
          Meetup: "Meetup",
          Close: "Close",
          "Add Booking": "Add Booking",
          Ratings: "Ratings",
          Bookings: "Bookings",
          Booking: "Booking",
          "Your Position": "Your Position",
          "Type a message here": "Type a message here",
          "Book Me": "Book Me",
          "Recent chat": "Recent chat",
          "Submit Rating": "Submit Rating",
          "Not Now": "Not Now",
          "Booking Date": "Booking Date",
          "Re-Book": "Re-Book",
          Detach: "Detach",
          Details: "Details",
          "Add Meetup Point": "Add Meetup Point",
          Profile: "Profile",
          "In Progress": "In Progress",
          "Interval /KM": "Interval /KM",
          Manual: "Manual",
          Others: "Others",
          others: "Others",
          Other: "Other",
          Role: "Role",
          "Car Rental Agent": "Car Rental Agent",
          "Rental Car Agent": "Rental Car Agent",
          "Start date": "Start date",
          "End date": "End date",
          "Filter by Date": "Filter by Date",
          "Filter by receiver": "Filter by receiver",
          All: "All",
          Filter: "Filter",
          "Booking with": "Booking with",
          History: "History",
          "My Procedure": "My Procedure",
          "My simplified procedures": "My simplified procedures",
          "Book a code date": "Book a code date",
          "Disputing From": "Disputing From",
          Comment: "Comment",
          Status: "Status",
          "FeedBack Date": "FeedBack Date",
          Dispute: "Dispute",
          "Master the handling of the vehicle in light or no traffic":
            "Master the handling of the vehicle in light or no traffic",
          "Drive the handling of the vehicle in light or no traffic": "Drive the handling of the vehicle in light or no traffic",
          "Apprehend the handling of the vehicle in light or no traffic":
            "Apprehend the handling of the vehicle in light or no traffic",
          Comments: "Comments",
          Wording: "Wording",
          Accquired: "Accquired",
          "Practice the handling of the vehicle in light or no traffic":
            "Practice the handling of the vehicle in light or no traffic",
          "IV - Master": "IV - Master",
          "III - Driving": "III - Driving",
          "II - Apprehend": "II - Apprehend",
          "I - Practice": "I - Practice",
          "My Learning": "My Learning",
          "Change Password": "Change Password",
          "Request Profile Update": "Request Profile Update",
          "No Language/s selected": "No Language/s selected",
          "Edit Profile": "Edit Profile",
          "Highway Code Date": "Highway Code Date",
          About: "About",
          "Contact Information": "Contact Information",
          Finish: "Finish",
          Next: "Next",
          Edit: "Edit",
          Add: "Add",
          "No file choosen.": "No file choosen.",
          "Driver’s License Number": "Driver’s License Number",
          "Authorization to Exercise": "Authorization to Exercise",
          "Date of the authorization to exercise": "Date of the authorization to exercise",
          "Type Of Contract With Driving School": "Type Of Contract With Driving School",
          "External Provider": "External Provider",
          Employee: "Employee",
          "No Instructors Added.": "No Instructors Added.",
          "Technical Control Details if any?": "Technical Control Details if any?",
          "Company Name": "Company Name",
          Tradename: "Tradename",
          "Terms and Condition applied": "Terms and Condition applied",
          Business: "Business",
          "Date of Approval": "Date of Approval",
          Number: "Number",
          "Upload your Certificate": "Upload your Certificate",
          Languages: "Languages",
          Language: "Language",
          "Which platform(s)?": "Which platform(s)?",
          "which platform(s)?": "Which platform(s)?",
          "I accept CPF financement": "I accept CPF financement",
          "Identity Of Instructor": "Identity Of Instructor",
          "Manager Identity": "Manager Identity",
          Approval: "Approval",
          "Confirm Identity": "Confirm Identity",
          "Driving School Registration": "Driving School Registration",
          "Date of 1st Registration": "Date of 1st Registration",
          "Upload Vehicle Registration Certificate Back": "Upload Vehicle Registration Certificate Back",
          "Upload Vehicle Registration Certificate Front": "Upload Vehicle Registration Certificate Front",
          "Upload Image": "Upload Image",
          "Date of Obtaining": "Date of Obtaining",
          Insurance: "Insurance",
          "Driver’s License": "Driver’s License",
          "Upload your authorization front side": "Upload your authorization front side",
          "Upload your authorization back side": "Upload your authorization back side",
          "Upload your Authorization Front Side": "Upload your Authorization Front Side",
          "Upload your Authorization Back Side": "Upload your Authorization Back Side",
          "Authorization Number": "Authorization Number",
          "Upload your Driving License Front Side": "Upload your Driving License Front Side",
          "Upload your driving license front side": "Upload your Driving License Front Side",
          "Upload your Driving License Back Side": "Upload your Driving License Back Side",
          "Upload your driving license back side": "Upload your Driving License Back Side",
          "Expiration date": "Expiration Date",
          "Expiration Date": "Expiration Date",
          "Date of Authorization to Excersice": "Date of Authorization to Excersice",
          "Validity Date": "Validity Date",
          "Issuing Authority": "Issuing Authority",
          "Place of Delivery": "Place of Delivery",
          "Driving License Number": "Driving License Number",
          "Are you working on other platform?": "Are you working on other platform?",
          "Which plateforms": "Which plateforms",
          "How much time will you give to the students of our platform?(hr/month)":
            "How much time will you give to the students of our platform?(hr/month)",
          Back: "Back",
          "All steps completed - you're finished": "All steps completed - you're finished",
          "Instructor Registration": "Instructor Registration",
          "Scan and upload your CT": "Scan and upload your CT",
          "Scan and Upload your CT": "Scan and Upload your CT",
          "Date of Technical Control": "Date of Technical Control",
          "Technical Control": "Technical Control",
          "Technical Control if any?": "Technical Control if any?",
          "Upload Scanned Green Card": "Upload Scanned Green Card",
          "Vehicle Insurance": "Vehicle Insurance",
          "Start Date": "Start Date",
          "End Date": "End Date",
          "Upload Vehicle Registration Certificate": "Upload Vehicle Registration Certificate",
          "Upload your Image": "Upload your Image",
          Energy: "Energy",
          "Number Plate": "Your number plate",
          "Date of the 1st Registration": "Date of the 1st Registration",
          Brand: "Brand",
          Model: "Model",
          Color: "Color",
          Gearbox: "Gearbox",
          GearBox: "GearBox",
          Welcome: "Welcome",
          "Vehicle Details": "Vehicle Details",
          "No Vehicle Added.": "No Vehicle Added.",
          "Must be 14 Digits": "Must be 14 Digits",
          "Upload your RC Pro": "Upload your RC Pro",
          "Expiration date of your insurance": "Expiration date of your insurance",
          "Expiration date of your Insurance": "Expiration date of your Insurance",
          "Police Number": "Police Number",
          Insurer: "Insurer",
          "Insurance Company": "Insurance Company",
          "Upload your KBLS": "Upload your KBLS",
          "Upload your Kbls": "Upload your Kbls",
          "Upload your kbls": "Upload your kbls",

          "SIRET Number": "SIRET Number",

          "Enter City": "Enter City",
          "How much time you will give to students of our platforms?":
            "How much time you will give to students of our platforms?",
          Vehicles: "Vehicles",
          "Instructor Identity": "Instructor Identity",
          "Rental Car Registration": "Rental Car Registration",
          "You asked OKLM with your friends and one of them just told you about permispop? Have you seen our ad or heard of us but are you in class or outisde Don’t leave!":
            "You asked OKLM with your friends and one of them just told you about permispop? Have you seen our ad or heard of us but are you in class or outisde Don’t leave!",
          "Student Registration": "Student Registration",
          "Are you there to register on PermisPop as a student? No problem! The registration is done in one step!":
            "Are you there to register on PermisPop as a student? No problem! The registration is done in one step!",
          "Choose File": "Choose File",
          "Click me if you had": "Click me if you had",
          "Terms & Conditions Applied": "Terms & Conditions Applied",
          Register: "Register ",
          "Upload Highway code image file": "Upload Highway code image file",
          "Upload your profile picture": "Upload your profile picture",
          "Upload your Profile Picture": "Upload your Profile Picture",
          "Upload back of your ID Card/Passport": "Upload back of your ID Card/Passport",
          "Upload front of your ID Card/Passport": "Upload front of your ID Card/Passport",
          "Spoken Language": "Spoken Language",
          "NEPH Number": "NEPH Number",
          "Number of hours": "Number of hours",
          "Confirm Password": "Confirm Password",
          Password: "Password",
          Complement: "Complement",
          City: "City",
          "Second Phone": "Second Phone",
          "Second Email": "Second Email",
          "First Name": "First Name",
          Surname: "Surname",
          "Date of Birth": "Date of Birth",
          "Place of Birth": "Place of Birth",
          Gender: "Gender",
          Male: "Male",
          male: "male",
          Female: "Female",
          female: "female",
          "Zip Code": "Zip Code",
          "You can provide all your documents and your NEPH mumber after validation of your profile isn’t that awesome? So let’s go, fill out the form and validate it!":
            "You can provide all your documents and your NEPH mumber after validation of your profile isn’t that awesome? So let’s go, fill out the form and validate it!",
          "Student Identity": "Student Identity",
          "Driving School": "Driving School",
          Instructor: "Instructor",
          Student: "Student",
          "Terms and Conditions": "Terms and Conditions",
          "Write your message here": "Write your message here",
          Send: "Send",
          Phone: "Phone",
          Address: "Address",
          Email: "Email",
          "Full Name": "Full Name",
          "Fill up the form and we will get back to you within 24 hours":
            "Fill up the form and we will get back to you within 24 hours",
          "Access The Highway Code Here": "Access The Highway Code Here",
          "The Full Road Code Only For €14.90": "The Full Road Code Only For €14.90",
          "Highway Code": "Highway Code",
          "Online Training That Will Revolution Your Code and License":
            "Online Training That Will Revolution Your Code and License",
          "Pop Code": "Pop Code",
          "User Behaviour": "User Behaviour",
          "Register As": "Register As",
          "Play Video": "Play Video",
          "Join Now": "Join Now",
          "Join us to enjoy quality driving education": "Join us to enjoy quality driving education",
          "Terms & Conditions": "Terms & Conditions",
          "The Revolutionary platform for true Driving education freelancers.":
            "The Revolutionary platform for true Driving education freelancers.",
          "Don’t have an account?": "Don’t have an account?",
          "I read and agree to": "I read and agree to",
          "Login to your account": "Login to your account",
          "Do you want to delete booking of this vehicle?": "Do you want to delete booking of this vehicle?",

          "Email (2nd email)": "Second Email",
          "Phone (2nd phone)": "Second Phone",
          Home: "Home",
          Services: "Services",
          ServicesHome: "Services",
          "About Us": "About Us",
          Testimonials: "Testimonials",
          "FAQ'S": "FAQ'S",
          Packages: "Packages",
          "Contact Us": "Contact Us",
          Login: "Login",
          "login-page": "Login Page",
          "enter-email-pass": "Enter Email Password",
          "Register now": "Register Now",
          "submit-question": "Submit",
          // "revolutionary-platform":
          //   "The revolutionary platform for true driving education freelancers.",
          "Join-us-text": "Join us to enjoy quality driving education.",
          "Student Registeration": "Student Registeration",
          "i-certify-neph": "I certify on my honour that the above information is true and I have obtain the code.",
          "neph-confirm-error": "should select this field to continue.",
          "valid-email-for-forgot-password": "Enter your valid email to recieve mail",
          "auto-complete-error": "Please Select From Dropdown to set Address",
          require1vehicleError: "Require atleast one vehicle",
          "20yearAgeError": "You Should be 20 year old.",
          "16yearAgeError": "You Should be 16 year old.",
          "23yearAgeError": "You Should be 23 year old.",
          greetings: "Welcome",
          no_chat_title: "No Chat Selected",
          no_chat_desc: "Please select any chat to start messaging",

          "Detach successfully": "Detach successfully",
          Add: "Add",
          submit: "submit",
          "Comment added successfully": "Comment added successfully",
          " Rating cannot be zero": " Rating cannot be zero",
          "Reminder cannot be empty": "Reminder cannot be empty",
          "Rating submitted successfully": "Rating submitted successfully",
          "Profile Image Changed successfully!": "Profile Image Changed successfully!",
          "Must select a vehicle to book!": " Must select a vehicle to book!",
          "Must select a meetup point!": "Must select a meetup point!",
          "Start and End date cannot be same!": " Start and End date cannot be same!",
          "Start date cannot be after End date!": "Start date cannot be after End date!",
          "Cannot create a availability for 30 minutes!": "Cannot create a availability for 30 minutes!",
          "Disponibilité Ajouté pour date": "Disponibilité Ajouté pour date",
          "Availabilty already exists for this time slot!": "Availabilty already exists for this time slot!",
          "Please enter a description!": "Please enter a description!",
          "Meetup points added successfully": "Meetup points added successfully",
          "Detach successfully": "Detach successfully",
          "Instrcutor added successfully": "Instrcutor added successfully",
          "Request is created for profile update": "Request is created for profile update",
          "Description cannot be empty": "Description cannot be empty",
          "New password does not match Confirm password": "New password does not match Confirm password",
          "Cannot accept empty fields": "Cannot accept empty fields",
          Comments: "Comments",
          "Comment is Empty": "Comment is Empty",
          "Not Found": "Not Found",
          "I - Master": "I - Master",
          "II - Apprehend": "II - Apprehend",
          "III - Driving": "III - Driving",
          "IV - Practice": "IV - Practice",
          "Practice the handling of the vehicle in light or no traffic":
            "Practice the handling of the vehicle in light or no traffic",
          "Apprehend the handling of the vehicle in light or no traffic":
            "Apprehend the handling of the vehicle in light or no traffic",
          "Drive the handling of the vehicle in light or no traffic": "Drive the handling of the vehicle in light or no traffic",
          "Master the handling of the vehicle in light or no traffic":
            "Master the handling of the vehicle in light or no traffic",
          "Learn Car Driving with Permispop": "Learn Car Driving with Permispop",
          "Cannot select this event": "Cannot select this event",
          "Can not select past event": "Can not select past event",
          "Booking Deleted successfully!": "Booking Deleted successfully!",
          "Booking time cannot exceed availability end time!": "Booking time cannot exceed availability end time!",
          "Booking time cannot less then availability start time!": "Booking time cannot less then availability start time!",
          "Booking Added successfully!": "Booking Added successfully!",
          "Unavailabilty Added for date": "Unavailabilty Added for date",
          "Personal Event Added for date": "Personal Event Added for date",
          "Late delivery": "Late delivery",
          "Payment issue": "Payment issue",
          Harassment: "Harassment",
          "Abusing Language": "Abusing Language",
          "Create Dispute": "Create Dispute",
          //-------------------------------------------------text paragraph
          "Reg-para1":
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          "Reg-para2":
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          "Login-para":
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          "Services-main-tag":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "Behaviour-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "bestTimeTrain-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "trainAllAges-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "individualApproach-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "calmIns-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "duplicatePedals-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "allDoc-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "bestClass-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "bestInstructor-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "how-long-is-road-tes":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "Faq-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          //--------------------------------------------------------------
          "accept-ds-text-page1-title": "Accept CPF",
          "accept-ds-text-page1": "I accept CPF financement",
          "footer-info-get-started":
            "Our social marketing solutions help more than 2500 companies around the world deliver great results. We can't stand average and our clients can't either.",
          "deactivate-message":
            "Your account is been deactivated by admin due to some condition.\nPlease contact admin for more information.\nContact",
          "deactive-title": "Account Deactive",
          "non-verify-title": "This is area accessable for only Verified users",
          "non-verfiy-teacher-title": "Only For Verified Users",
          "non-verify-message": "Please Upload missing documents to get access to this feature.",
          "non-verify-teacher-message": "Only for Verified Users! admin on admin@permispop.fr.",
          "member-title": "Only member access",
          "member-message": "To access this page please by membership",

          "neph-length-error": "neph length must be 12 digits.",
          "neph-numeric-error": "neph must be numeric only.",
          // my learning
          myLearningLeagalInfoMessage: "*Click on text to open popup details.",
          closeBtnMylearning: "Close",

          // my learing lesson desciprtion\
          myLearingLessonDescription1: "Master the handling of the vehicle in light or no traffic.",
          myLearingLessonDescription2: "Understand the road and travel under normal conditions.",
          myLearingLessonDescription3: "Travel in difficult conditions and share the road with other users.",
          myLearingLessonDescription4: "Practice autonomous, safe and economical driving.",
          // end of my learing lesson description

          // my learing Items translation
          myLearningLesson1:
            "A turn in the road must be performed without hitting or touching the curb and within as few moves as necessary",
          myLearningLesson2: "This is the first lesson where you learn what does what in the car.",
          myLearningLesson3: "Tenir, tourner le volant et maintenir la trajectoire.",
          myLearningLesson4: "Démarrer et s’arrêter.",
          myLearningLesson5: "Doser l’accélération et le freinage à diverses allures.",
          myLearningLesson6: "Utiliser la boîte de vitesses.",
          myLearningLesson7: "Diriger la voiture en avant en ligne droite et en courbe en adaptant allure et trajectoire.",
          myLearningLesson8: "Regarder autour de soi et avertir.",
          myLearningLesson9: "Effectuer une marche arrière et un demi-tour en sécurité.",
          // Compétence 2
          myLearningLesson10: "Entrer, s’installer au poste de conduite et en sortir.",
          myLearningLesson11: "Rechercher la signalisation, les indices utiles et en tenir compte.",
          myLearningLesson12: "Positionner le véhicule sur la chaussée et choisir la voie de circulation.",
          myLearningLesson13: "Adapter l’allure aux situations.",
          myLearningLesson14: "Tourner à droite et à gauche en agglomération.",
          myLearningLesson15: "Détecter, identifier et franchir les intersections suivant le régime de priorité.",
          myLearningLesson16: "Franchir les ronds-points et les carrefours à sens giratoire.",
          myLearningLesson17: "S’arrêter et stationner en épi, en bataille et en créneau.",
          // Compétence 3
          myLearningLesson18: "Evaluer et maintenir les distances de sécurité.",
          myLearningLesson19: "Croiser, dépasser, être dépassé.",
          myLearningLesson20: "Passer des virages et conduire en déclivité.",
          myLearningLesson21:
            "Connaître les caractéristiques des autres usagers et savoir se comporter à leur égard avec respect et courtoisie.",
          myLearningLesson22: "S’insérer, circuler et sortir d’une voie rapide.",
          myLearningLesson23: "Conduire dans une file de véhicules et dans une circulation dense.",
          myLearningLesson24: "Conduire quand l’adhérence et la visibilité sont réduites.",
          // Compétence 4
          myLearningLesson25: "Suivre un itinéraire de manière autonome.",
          myLearningLesson26: "Préparer et effectuer un voyage « longue distance » en autonomie.",
          myLearningLesson27: "Connaître les principaux facteurs de risque au volant et les recommandations à appliquer.",
          myLearningLesson28: "Connaître les comportements à adopter en cas d’accident : protéger, alerter, secourir.",
          myLearningLesson29:
            "Faire l’expérience des aides à la conduite du véhicule (régulateur, limiteur de vitesse, ABS,aides à la navigation...).",
          myLearningLesson30: "Avoir des notions sur l’entretien, le dépannage et les situations d’urgence.",
          myLearningLesson31: "Pratiquer l’écoconduite.",

          // ---------------------

          validityDateErrorInstructor: "Validatity date can't be less than to obtain date.",
          pastExpireError: "Expire date should not be in past.",
          pastDateError: "date should not be in past.",
          kbis14digits: "Sirel must be 14 digits.",
          futureDateRequiredError: "Date should be future date.",
          futureDateNotError: "Date should not be future date.",
          InsuranceCompanyRegister: "Insurance Company",
          acceptManagerCondition: "You must accept the general conditions.",
          instructorAgeError: "Instructor should be 20 years old.",
          "Password cannot be empty": "Password cannot be empty",
          "Password didn't match": "Password didn't match",
          "Password must be atleast 8 char long": "Password must be atleast 8 char long",

          "Student-Reg-para1":
            "Are you there to register on PermisPop as a student? No problem! The registration is done in one step! \nYou asked OKLM with your friends and one of them just told you about PermisPop? Have you seen our ad or heard of us but are you in class or outside? Don't leave!\nYou can provide all your documents and your NEPH number after validation of your profile! Isn't that awesome? So let's go, fill out the form and validate it!",
          //instructor  validation translation
          '"First Name" is not allowed to be empty': '"First Name" is not allowed to be empty',
          '"Prénom" is not allowed to be empty': '"Prénom" is not allowed to be empty',

          '"First Name" must be atleast 2 alphabets Longs!': '"First Name" must be atleast 2 alphabets Longs!',
          '"Prénom" must be atleast 2 alphabets Longs!': '"Prénom" must be atleast 2 alphabets Longs!',

          '"Surname" is not allowed to be empty': '"Surname" is not allowed to be empty',
          '"Nom" is not allowed to be empty': '"Nom" is not allowed to be empty',

          '"Address" is not allowed to be empty': '"Address" is not allowed to be empty',
          '"Adresse" is not allowed to be empty': '"Address" is not allowed to be empty',

          '"pob" is not allowed to be empty': "Your place of birth cannot be empty",

          "Email Field can't be empty!": "Email Field can't be empty!",
          "E-mail Field can't be empty!": "Email Field can't be empty!",

          '"Phone" is not allowed to be empty': '"Phone" is not allowed to be empty',
          '"Téléphone" is not allowed to be empty': '"Téléphone" is not allowed to be empty',

          '"licence No." is not allowed to be empty': "Licence number cannot be empty",
          '"Numéro de licence" is not allowed to be empty': '"Numéro de licence" is not allowed to be empty',

          '"delivery Place" is not allowed to be empty': '"delivery Place" is not allowed to be empty',
          '"adresse de livraison" is not allowed to be empty': '"adresse de livraison" is not allowed to be empty',

          '"Issuing Authority" is not allowed to be empty': '"Issuing Authority" is not allowed to be empty',
          '"Autorité de délivrance" is not allowed to be empty': '"Autorité de délivrance" is not allowed to be empty',

          '"Authorization Number" is not allowed to be empty': '"Authorization Number" is not allowed to be empty',
          '"Numéro d autorisation" is not allowed to be empty': '"Numéro d autorisation" is not allowed to be empty',

          '"siret No." is not allowed to be empty': '"siret No." is not allowed to be empty',
          '"n° de siret" is not allowed to be empty': '"n° de siret" is not allowed to be empty',

          '"company Insurer" is not allowed to be empty': "Your company's insurer cannot be empty",

          '"Police Num" is not allowed to be empty': '"Police Num" is not allowed to be empty',
          '"Numéro de police" is not allowed to be empty': '"Numéro de police" is not allowed to be empty',

          '"NumPlate" is not allowed to be empty': '"NumPlate" is not allowed to be empty',
          '"Plaque d immatriculation" is not allowed to be empty': '"Plaque d immatriculation" is not allowed to be empty',

          '"Color" is not allowed to be empty': "Color cannot be empty",
          '"Couleur" is not allowed to be empty': '"Couleur" is not allowed to be empty',

          '"Energy" is not allowed to be empty': "Energy cannot be empty",
          '"Énergie" is not allowed to be empty': '"Énergie" is not allowed to be empty',

          '"Insurer" is not allowed to be empty': "Your insurer cannot be empty",
          '"Assureur" is not allowed to be empty': "Your insurer cannot be empty",

          // car rental validation translation

          '"SurName" is not allowed to be empty': '"SurName" is not allowed to be empty',

          '"Pob" is not allowed to be empty': "Your place of birth cannot be empty",

          '"Siret" is not allowed to be empty': '"Siret" is not allowed to be empty',

          '"CompanyInsurer" is not allowed to be empty': '"CompanyInsurer" is not allowed to be empty',

          '"Company Police Number" is not allowed to be empty': "Company's police number cannot be empty",
          '"Numéro de police de l entreprise" is not allowed to be empty': "Company's police number cannot be empty",

          '"VehicleInsurer" is not allowed to be empty': '"VehicleInsurer" is not allowed to be empty',
          '"Assureur du véhicule" is not allowed to be empty': '"Assureur du véhicule" is not allowed to be empty',

          '"PoliceNumber" is not allowed to be empty': '"PoliceNumber" is not allowed to be empty',

          // Driving School validation translation

          '"Trade Name" is not allowed to be empty': "Your company's trade name cannot be empty",
          '"Nom commercial" is not allowed to be empty': '"Nom commercial" is not allowed to be empty',

          '"Company Name" is not allowed to be empty': '"Company Name" is not allowed to be empty',
          '"Nom de la société" is not allowed to be empty': '"Nom de la société" is not allowed to be empty',

          '"Number" is not allowed to be empty': '"Number" is not allowed to be empty',
          '"Numéro" is not allowed to be empty': '"Numéro" is not allowed to be empty',

          '"Issuing Aurh" is not allowed to be empty': '"Issuing Aurh" is not allowed to be empty',
          '"Autorisation de délivrance" is not allowed to be empty': '"Autorisation de délivrance" is not allowed to be empty',

          '"Siret Number" is not allowed to be empty': '"Siret Number" is not allowed to be empty',
          '"Numéro de Siret" is not allowed to be empty': '"Numéro de Siret" is not allowed to be empty',

          '"Insurance Company" is not allowed to be empty': "Insurance company cannot be empty",
          '"Compagnie d assurance" is not allowed to be empty': "Insurance company cannot be empty",

          '"Police Number" is not allowed to be empty': "Police number cannot be empty",

          '"Firstname" is not allowed to be empty': '"Firstname" is not allowed to be empty',

          '"NumberPlate" is not allowed to be empty': '"NumberPlate" is not allowed to be empty',

          '"DriverLicenseNumber" is not allowed to be empty': '"DriverLicenseNumber" is not allowed to be empty',
          '"Numéro de permis de conduire" is not allowed to be empty':
            '"Numéro de permis de conduire" is not allowed to be empty',

          '"ExcAuthIssueAuth" is not allowed to be empty': '"ExcAuthIssueAuth" is not allowed to be empty',

          '"ExcAuthNumber" is not allowed to be empty': '"ExcAuthNumber" is not allowed to be empty',

          "Best Time Training": "Best Time Training",
          "Enter email": "Enter email",
          Manaager: "Manager",

          "First Name must be atleast 2 alphabets Longs!": "First Name must be atleast 2 alphabets Longs!",
          "Prénom must be atleast 2 alphabets Longs!": "First Name must be atleast 2 alphabets Longs!",

          "Surname must be atleast 2 alphabets Longs!": "Surname must be atleast 2 alphabets Longs!",
          "Nom must be atleast 2 alphabets Longs!": "Nom must be atleast 2 alphabets Longs!",

          "Not a Valid Email": "Not a Valid Email",
          "Not a Valid E-mail": "Not a Valid E-mail",
          '"Phone" length must be 10 characters long': "Your phone number's length must be 10 characters long",
          "Trade Name must be atleast 3 alphabets Longs!": "Your company's trade name must be at least 3 characters long",
          '"siret No." length must be 14 characters long': "Your SIRET's length must be 14 characters long",
          '"Siret" length must be 14 characters long': "Your SIRET's length must be 14 characters long",
          "SurName must be atleast 2 alphabets Longs!": "SurName must be atleast 2 alphabets Longs!",
          '"CompanyInsurer" length must be at least 3 characters long':
            "Your company insurer's length must be at least 3 characters long",
          '"VehicleInsurer" length must be at least 3 characters long':
            "Your vehicle insurer's length must be at least 3 characters long",
          '"Téléphone" length must be 10 characters long': '"Téléphone" length must be 10 characters long',
          '"Siret Number" length must be at least 14 characters long':
            '"Siret Number" length must be at least 14 characters long',
          '"Insurance Company" length must be at least 3 characters long':
            "Your insurance company's name's length must be at least 3 characters long",
          '"Firstname" length must be at least 2 characters long': "Your firstname's length must be at least 2 characters long",
          '"Surname" length must be at least 2 characters long': "Your surname's length must be at least 2 characters long",
          '"Insurer" length must be at least 2 characters long': '"Insurer" length must be at least 2 characters long',
          '"Email" is not allowed to be empty': '"Email" is not allowed to be empty',
          '"Email" must be a valid email': '"Email" must be a valid email',
          "Old password": "Old password",
          "New password": "New password",
          "Confirm password": "Confirm password",
          "Enter password": "Enter password",
          Closed: "Closed",
          Complete: "Complete",
          CarRentalAgent: "CarRentalAgent",
          DrivingSchool: "Driving School",
          DSInstructor: "DSInstructor",
          "The 2nd phone number cannot be the same as the 1st": "The 2nd phone number cannot be the same as the 1st",
          "Phone number should start with 0": "Phone number should start with 0",
          "The 2nd email address cannot be the same as the 1st email":
            "The 2nd email address cannot be the same as the 1st email",
          "Email 2 can’t be the same as the first email": "Email 2 can’t be the same as the first email",
          "Only for Verified Users!": "Only for Verified Users!",
          "This area will be accessible once admin verify your profile,":
            "This area will be accessible once admin verify your profile,",
          "or you can send your non-verified documents": "or you can send your non-verified documents",
          "to admin on": "to admin on",
          "No address found": "No address found",
          "Phone must be 10 digits": "Phone must be 10 digits",
          "Phone can't be blank": "Phone number cannot be empty",
          "The phone must be at least 10 characters": "The phone must be at least 10 characters",
          "Phone 2 can’t be the same as the first phone number": "Phone 2 can’t be the same as the first phone number",
          "Instructor must be 22 years or older": "Instructor must be 22 years or older",
          "Driving School Manager must be 22 years or older": "Driving School Manager must be 22 years or older",
          "Driving School Instructor must be 22 years or older": "Driving School Instructor must be 22 years or older",
          "Car Rental Agent must be 22 years or older": "Car Rental Agent must be 22 years or older",
          "Student must be 16 years or older": "Student must be 16 years or older",
          "Enter a Valid Date": "Enter a Valid Date",
          "Your SIRET number cannot be empty": "Your SIRET number cannot be empty",
          "Your SIRET's length must be 14 characters long": "Your SIRET's length must be 14 characters long",
          '"company Insurer" length must be at least 3 characters long':
            "Your company insurer's length must be at least 3 characters long",
        },
      },
      fr: {
        translations: {
          '"company Insurer" length must be at least 3 characters long':
            "L'assureur de votre société doit être de minimum 3 caractères",
          "Your SIRET's length must be 14 characters long": "La longueur du N° SIRET doit être de 14 caractères",
          "Your SIRET number cannot be empty": "Votre N° SIRET ne peut pas être vide",
          "Enter a Valid Date": "Entrez une date valide",
          "Instructor must be 22 years or older": "L'Responsable d'auto-école ne peut pas avoir moins de 22 ans",
          "Driving School Manager must be 22 years or older": "L'instructeur ne peut pas avoir moins de 22 ans",
          "Driving School Instructor must be 22 years or older":
            "L'Moniteur d'école de conduite ne peut pas avoir moins de 22 ans",
          "Car Rental Agent must be 22 years or older": "L'Agent de location de voitures ne peut pas avoir moins de 22 ans",
          "Student must be 16 years or older": "L'étudiant ne peut pas avoir moins de 16 ans",
          "Phone 2 can’t be the same as the first phone number":
            "Le 2ème numéro de téléphone ne peut pas être le même que le 1er",
          "No address found": "Aucune adresse trouvée",
          "to admin on": "à admin sur ",
          "or you can send your non-verified documents": "ou vous pouvez envoyer vos documents non vérifiés",
          "This area will be accessible once admin verify your profile,":
            "Cette section sera accessible une fois que l'administrateur aura vérifié votre profil",
          "Only for Verified Users!": "Uniquement pour les utilisateurs vérifiés !",
          "Email 2 can’t be the same as the first email": "La 2ème adresse mail ne peut pas être la même que la 1ère",
          "The 2nd email address cannot be the same as the 1st email":
            "La 2ème adresse mail ne peut pas être la même que la 1ère",
          "Phone number should start with 0": "Le numéro de téléphone doit commencer par 0",
          "The 2nd phone number cannot be the same as the 1st": "Le 2ème numéro de téléphone ne peut pas être le même que le 1er",
          "Instructor Registered Successfully": "Votre profil instructeur a été créé avec succès !",

          "Old password": "Ancien mot de passe",
          "New password": "Nouveau mot de passe",
          "Enter password": "Entrer le mot de passe",
          "Confirm password": "Confirmer le mot de passe",

          '"Email" must be a valid email': "L'e-mail doit être un e-mail valide",
          '"Email" is not allowed to be empty': "L'e-mail ne doit pas être vide",

          '"Insurer" length must be at least 2 characters long':
            "La longueur du nom de l'assureur doit être d'au moins 2 caractères",

          '"Surname" length must be at least 2 characters long': "Votre nom doit être de minimum 2 caractères",

          '"Firstname" length must be at least 2 characters long': "Votre prénom doit être de minimum 2 caractères",
          '"Insurance Company" length must be at least 3 characters long':
            "La longueur de la compagnie d'assurance doit être de minimum 3 caractères",
          '"Téléphone" length must be 10 characters long': "La longueur de votre téléphone doit être de 10 chiffres",
          "The phone must be at least 10 characters": "La longueur de votre téléphone doit être de 10 chiffres",
          '"Phone" length must be 10 characters long': "La longueur de votre téléphone doit être de 10 chiffres",
          "Trade Name must be atleast 3 alphabets Longs!": "Le nom commercial de votre société doit être de minimum 3 caractères",
          '"VehicleInsurer" length must be at least 3 characters long':
            "L'assureur de votre véhicule doit être de minimum 3 caractères",
          '"CompanyInsurer" length must be at least 3 characters long':
            "L'assureur de votre société doit être de minimum 3 caractères",
          "SurName must be atleast 2 alphabets Longs!": "Le nom doit être de minimum 2 caractères",
          '"Siret" length must be 14 characters long': "La longueur du N° SIRET doit être de 14 caractères",
          '"Siret Number" length must be at least 14 characters long': "La longueur du N° SIRET doit être de 14 caractères",
          '"siret No." length must be 14 characters long': "La longueur du N° SIRET doit être de 14 caractères",
          "Not a Valid Email": "L’adresse mail n’est pas correcte",
          "Not a Valid E-mail": "L’adresse mail n’est pas correcte",
          // Driving School validation translation

          "Surname must be atleast 2 alphabets Longs!": "Le nom doit être de minimum 2 caractères",
          "Nom must be atleast 2 alphabets Longs!": "Le nom doit être de minimum 2 caractères",

          '"Address" is not allowed to be empty': "L'adresse ne doit pas être vide",
          '"Adresse" is not allowed to be empty': "L'adresse ne doit pas être vide",

          "First Name must be atleast 2 alphabets Longs!": "Le prénom doit être de minimum 2 caractères",
          "Prénom must be atleast 2 alphabets Longs!": "Le prénom doit être de minimum 2 caractères",

          '"ExcAuthNumber" is not allowed to be empty': "Numéro d'autorisation est pas autorisé à être vide",

          '"ExcAuthIssueAuth" is not allowed to be empty': '"Autorité de délivrance" est pas autorisé à être vide',

          '"DriverLicenseNumber" is not allowed to be empty': '"Numéro de permis de conduire" est pas autorisé à être vide',
          '"Numéro de permis de conduire" is not allowed to be empty':
            '"Numéro de permis de conduire" est pas autorisé à être vide',

          '"NumberPlate" is not allowed to be empty': '"Plaque d immatriculation" est pas autorisé à être vide',
          '"Plaque d immatriculation" is not allowed to be empty': '"Plaque d immatriculation" est pas autorisé à être vide',

          '"Firstname" is not allowed to be empty': "Le prénom ne peut pas être vide",
          '"Prénom" is not allowed to be empty': "Le prénom ne peut pas être vide",

          '"Police Number" is not allowed to be empty': "Le numéro de police ne peut pas être vide",
          '"Numéro de police" is not allowed to be empty': "Le numéro de police ne peut pas être vide",

          '"Insurance Company" is not allowed to be empty': "La compagnie d'assurance ne peut pas être vide",
          '"Compagnie d assurance" is not allowed to be empty': "La compagnie d'assurance ne peut pas être vide",

          '"Siret Number" is not allowed to be empty': '"Numéro de Siret" est pas autorisé à être vide',
          '"Numéro de Siret" is not allowed to be empty': '"Numéro de Siret" est pas autorisé à être vide',

          '"Issuing Aurh" is not allowed to be empty': '"Autorisation de délivrance" est pas autorisé à être vide',
          '"Autorisation de délivrance" is not allowed to be empty': '"Autorisation de délivrance" est pas autorisé à être vide',

          '"Number" is not allowed to be empty': '"Numéro" est pas autorisé à être vide',
          '"Numéro" is not allowed to be empty': '"Numéro" est pas autorisé à être vide',

          '"Company Name" is not allowed to be empty': '"Nom de la société" est pas autorisé à être vide',
          '"Nom de la société" is not allowed to be empty': '"Nom de la société" est pas autorisé à être vide',

          '"Trade Name" is not allowed to be empty': "Le nom commercial de votre société ne peut pas être vide",
          '"Nom commercial" is not allowed to be empty': "Le nom commercial de votre société ne peut pas être vide",

          // car rental validation translation

          '"PoliceNumber" is not allowed to be empty': '"Numéro de police" est pas autorisé à être vide',

          '"VehicleInsurer" is not allowed to be empty': "L'assureur de votre véhicule ne peut pas être vide",
          '"Assureur du véhicule" is not allowed to be empty': "L'assureur de votre véhicule ne peut pas être vide",

          '"Company Police Number" is not allowed to be empty': "Le numéro de police de l'entreprise ne peut pas être vide",
          '"Numéro de police de l entreprise" is not allowed to be empty':
            "Le numéro de police de l'entreprise ne peut pas être vide",

          '"CompanyInsurer" is not allowed to be empty': '"Compagnie Assureur" est pas autorisé à être vide',
          '"Compagnie Assureur" is not allowed to be empty': '"Compagnie Assureur" est pas autorisé à être vide',

          '"Siret" is not allowed to be empty': '"n° de siret" est pas autorisé à être vide',
          '"n° de siret" is not allowed to be empty': '"n° de siret" est pas autorisé à être vide',

          '"Pob" is not allowed to be empty': "Votre lieu de naissance ne peut pas être vide",

          '"SurName" is not allowed to be empty': "Le nom ne peut pas être vide",
          '"Nom" is not allowed to be empty': "Le nom ne peut pas être vide",

          //instructor  validation translation
          "Email Field can't be empty!": "L'email ne peut pas être vide",
          "E-mail Field can't be empty!": "L'email ne peut pas être vide",

          '"Insurer" is not allowed to be empty': "Votre assureur ne peut pas être vide",
          '"Assureur" is not allowed to be empty': "Votre assureur ne peut pas être vide",

          '"Energy" is not allowed to be empty': "Carburant ne peut pas être vide",
          '"Énergie" is not allowed to be empty': "Énergie ne peut pas être vide",

          '"Color" is not allowed to be empty': "La couleur ne peut pas être vide",
          '"Couleur" is not allowed to be empty': "La couleur ne peut pas être vide",

          '"NumPlate" is not allowed to be empty': "La plaque d’immatriculation ne peut pas être vide",

          '"Police Num" is not allowed to be empty': "Le n° de police ne peut pas être vide",

          '"company Insurer" is not allowed to be empty': "L'assureur de votre société ne peut pas être vide",

          '"siret No." is not allowed to be empty': "Le n° de SIRET ne peut pas être vide",

          '"Authorization Number" is not allowed to be empty': "Le numéro d’autorisation ne peut pas être vide",
          '"Numéro d autorisation" is not allowed to be empty': '"Numéro d autorisation" est pas autorisé à être vide',

          '"Issuing Authority" is not allowed to be empty': '"Autorité de délivrance" est pas autorisé à être vide',
          '"Autorité de délivrance" is not allowed to be empty': '"Autorité de délivrance" est pas autorisé à être vide',

          '"licence No." is not allowed to be empty': "Le n° de permis de conduire ne peut pas être vide",
          '"adresse de livraison" is not allowed to be empty': '"adresse de livraison" est pas autorisé à être vide',

          '"delivery Place" is not allowed to be empty': "Numéro de licence ne peut pas être vide",
          '"Numéro de licence" is not allowed to be empty': "Numéro de licence ne peut pas être vide",
          "Phone can't be blank": "Le n° de téléphone ne peut pas être vide",
          '"Phone" is not allowed to be empty': "Le n° de téléphone ne peut pas être vide",
          '"Téléphone" is not allowed to be empty': "Le n° de téléphone ne peut pas être vide",

          '"pob" is not allowed to be empty': "Votre lieu de naissance ne peut pas être vide",

          '"Surname" is not allowed to be empty': "Le nom ne peut pas être vide",

          '"First Name" must be atleast 2 alphabets Longs!': "Le prénom doit être de minimum 2 caractères",
          '"Prénom" must be atleast 2 alphabets Longs!': "Le prénom doit être de minimum 2 caractères",

          '"First Name" is not allowed to be empty': "Le prénom ne peut pas être vide",
          // french
          "Password didn't match": "Le mot de passe ne correspond pas",
          "Password cannot be empty": "Le mot de passe ne peut pas être vide",
          "Password must be atleast 8 char long": "Le mot de passe doit comporter au moins 8 caractères",
          "Instructor Name": "Nom de l'enseignant",
          Picture: "Image",
          "Booking with": "Réservation avec",
          "Date of registration": "Date d'enregistrement",
          Unavailability: "Indisponibilité",
          "test data for heading": "données de test pour le cap",
          "test data for headingtest data for heading": "données de test pour le capdonnées de test pour le cap",
          "Issueing Authority": "Autorité de délivrance",
          "Exercise Authorization": "Autorisation d'exercice",
          "Gear Box": "Boîte de vitesses",
          "invalid date": "date invalide",
          "Minimum 3 rows": "Minimum 3 lignes",
          Rankings: "Évaluations",
          "Instructure On Map": "Enseignant  sur la carte",
          "Instructor On Map": "Enseignant sur la carte",
          "Find Instructure": "Trouver une enseignant",
          "Find Instructor": "Trouver un enseignant",
          Chat: "Chat",
          "Can only select your bookings": "Vous ne pouvez sélectionner que vos réservations",
          "Cannot Book after time": "Impossible de réserver après l'heure",
          "No connection to server yet.": "Problème de connexion au serveur",
          "can not select past event": "Vous ne pouvez pas sélectionner un événement passé",
          "can not select past time": "Vous ne pouvez pas sélectionner le temps passé",
          "Vehicle added scuccessfully": "Véhicule ajouté avec succès",
          "Vehicle deleted scuccessfully": "Véhicule supprimé avec succès",
          "Vehicle detail updated sucessfully": "Détails du véhicule mis à jour avec succès",
          "Password updated Successfully": "Mot de passe mis à jour avec succès",
          "New password does not match Confirm password":
            "Votre nouveau mot de passe ne correspond pas, confirmez votre mot de passe",
          "Cannot accept empty fields": "Impossible d'accepter les champs vides",
          "Field cannot be empty": "Le champ ne peut pas être vide",
          "Dispute is created sucessfully": "Le litige a été créé avec succès",
          "Status is updated successful": "Le statut a été mis à jour avec succès",
          "Description cannot be empty": "La description ne peut pas être vide",
          "Request is created for profile update": "La demande de mise à jour du profil a été créée",
          "Image not Uploaded": "L'image n'a pas été téléchargée",
          "Something Went Wrong": "Une erreur s'est produite",
          "Email or Password is not correct": "Votre email ou votre mot de passe n'est pas correct",
          name: "Nom",
          photo: "photo",
          historyActions: "actionHistorique",
          "Student Registered Successfully": "Votre profil étudiant a été créé avec succès",
          "Rental Car Registered Successfully": "Votre profil d'agent de location de voiture a été créé avec succès",
          "Not Registered....Error Occured": "Non enregistré.... Une erreur s'est produite",
          "Not Registered....Somethong went wrong": "Non enregistré....Quelque chose s'est mal passé",
          "Driving School Registered Successfully": "Votre profil auto-école a été créé avec succès",
          "No data found": "Aucune donnée trouvée",
          "Enter value": "Entrez la valeur",
          "No value found": "Aucune valeur trouvée",
          "Availability Auto": "Disponibilité boîte automatique",
          "Availablity Manual": "Disponibilité boîte manuelle",
          "Add Availibity": "Ajouter une disponibilité",
          Week: "Semaine",
          "Meetup point": "Point de rendez-vous",
          "Meetup points": "Points de rendez-vous",
          "Serial Number": "Numéro de série",
          "Insurance Details": "Détails de l'assurance",
          "Would like to delete your availability?": "Souhaitez-vous supprimer votre disponibilité ?",
          Description: "Description",
          "Add Unavailability": "Ajouter une indisponibilité",
          "Enter username": "Saisissez votre nom d'utilisateur",
          "Add booking date": "Ajouter une date de réservation",
          "Select booking time": "Sélectionnez l'heure de réservation",
          "Instrcutor added successfully": "Instructeur ajouté avec succès",

          "Enter duration of booking": "Entrez la durée de la réservation",
          Vechicle: "Véhicule",
          "Select vechicle type": "Sélectionnez le type de véhicule",
          "Enter Meet-UP point": "Entrer dans le point de rendez-vous",
          "Add Booking": "Ajouter une réservation",
          Ratings: "Evaluations",
          "Type a message here": "Tapez un message ici",
          "Recent chat": "Chat récent",
          "Submit Rating": "Soumettre une évaluation",
          "Not Now": "Pas maintenant",
          Reservations: "Réservations",
          reservations: "Réservations",
          "Interval /KM": "Intervalle /KM",
          Others: "Autres",
          others: "Autres",
          Other: "Autre",
          // "Pending request":"Demande en attente",
          // "pending request":"Demande en attente",
          // "pending Request":"Demande en attente",
          "Pending Request": "Demande en attente",
          "Pending request": "Demande en attente",
          "pending request": "Demande en attente",
          "pending Request": "Demande en attente",
          "Start date": "Date de début",
          "End date": "Date de fin",
          "Filter by Date": "Filtrer par date",
          "Filter by receiver": "Filtrer par récepteur",
          "My Procedure": "Ma procédure",
          "Book a code date": "Reserver une date de code",
          "My simplified procedures": "Mes demarches simplifees",
          "Disputing From": "Contestation de",
          "FeedBack Date": "Date de retour",
          "Master the handling of the vehicle in light or no traffic":
            "Maîtriser la tenue de route du véhicule en circulation faible ou nulle",
          "Drive the handling of the vehicle in light or no traffic": "Conduire le véhicule dans un trafic léger ou nul",
          "Apprehend the handling of the vehicle in light or no traffic":
            "Appréhender le comportement du véhicule en trafic faible ou nul",
          Accquired: "Acquis",
          "Practice the handling of the vehicle in light or no traffic":
            "Pratiquer la conduite du véhicule dans un trafic léger ou nul",
          "IV - Master": "IV - Maîtrise",
          "III - Driving": "III - Conduite",
          "II - Apprehend": "II - Appréhender",
          "I - Practice": "I - Entraînement",
          "No Language/s selected": "Aucune langue/s sélectionnée",
          "Edit Profile": "Mettre à jour mon profil",
          About: "A propos",
          "Contact Information": "Coordonnées",
          Finish: "Finir",
          "No file choosen.": "Aucun fichier choisi.",
          "Type Of Contract With Driving School": "Type de contrat avec l'école de conduite",
          "No Instructors Added.": "Aucun instructeur ajouté.",
          "Technical Control Details if any?": "Détails du contrôle technique, le cas échéant ?",
          "Terms and Condition applied": "Termes et conditions appliqués",
          "Upload your Certificate": "Téléchargez votre certificat",
          "I accept CPF financement": "J'accepte le financement CPF",
          "Confirm Identity": "Confirmer l'identité",
          "Driving School Registration": "Inscription à l'école de conduite",
          "Date of 1st Registration": "Date de 1ère inscription",
          "Upload Vehicle Registration Certificate Back": "Télécharger le verso du certificat d'immatriculation du véhicule",
          "Upload Vehicle Registration Certificate Front": "Télécharger le recto du certificat d'immatriculation du véhicule",
          "Driver’s License": "Permis de conduire",
          "Date of Authorization to Excersice": "Date d'autorisation d'exercice",
          "Driving License Number": "Votre numéro de permis de conduire",
          "Are you working on other platform?": "Travaillez-vous sur une autre plateforme ?",
          "Which plateforms": "Quelles plateformes",
          "All steps completed - you're finished": "Toutes les étapes ont été complétées - vous avez terminé",
          "Instructor Registration": "Inscription des enseignants",
          "Technical Control if any?": "Contrôle technique s'il y a lieu ?",
          "Upload your Image": "Téléchargez votre image",
          "No Vehicle Added.": "Aucun véhicule ajouté.",
          "Must be 14 Digits": "Doit être de 14 chiffres",
          "Enter City": "Entrez la ville",
          "How much time you will give to students of our platforms?":
            "Combien de temps accorderez-vous aux étudiants de nos plateformes ?",
          "You asked OKLM with your friends and one of them just told you about permispop? Have you seen our ad or heard of us but are you in class or outisde Don’t leave!":
            "Vous avez posé la question à OKLM avec vos amis et l'un d'eux vient de vous parler de permispop ? Avez-vous vu notre annonce ou entendu parler de nous mais êtes-vous en classe ou à l'extérieur Ne partez pas !",
          "Are you there to register on PermisPop as a student? No problem! The registration is done in one step!":
            "Vous êtes là pour vous inscrire sur PermisPop en tant qu'étudiant ? Aucun problème! L'inscription se fait en une seule étape !",
          "Student Registration": "Inscription des étudiants",
          "Choose File": "Choisir le fichier",
          "Upload Highway code image file": "Télécharger le fichier image du code de la route",
          "Upload your profile picture": "Téléchargez votre photo de profil",
          "Upload your Profile Picture": "Téléchargez votre photo de profil",
          "Upload back of your ID Card/Passport": "Téléchargez votre carte d'identité/passeport",
          "Upload front of your ID Card/Passport": "Téléchargez le recto de votre carte d'identité/passeport",
          "Number of hours": "Nombre d'heures",
          "Second Phone": "Deuxième téléphone",
          "Second Email": "Deuxième e-mail",
          "You can provide all your documents and your NEPH mumber after validation of your profile isn’t that awesome? So let’s go, fill out the form and validate it!":
            "Vous pouvez fournir tous vos documents et votre numéro NEPH après validation de votre profil n'est-ce pas génial ? Alors c'est parti, remplissez le formulaire et validez-le !",
          "Terms and Conditions": "Termes et conditions",
          "Write your message here": "Ecrivez votre message ici",
          Send: "Envoyer",
          "Full Name": "Nom complet",
          "Fill up the form and we will get back to you within 24 hours":
            "Remplissez le formulaire et nous vous répondrons dans les 24 heures",
          "Access The Highway Code Here": "Accéder au code de la route ici",
          "The Full Road Code Only For €14.90": "Le code de la route complet seulement pour 14,90 €",
          "Highway Code": "Code de la route",
          "Online Training That Will Revolution Your Code and License":
            "Une formation en ligne qui révolutionnera votre code et votre licence",
          "Pop Code": "Code pop",
          "User Behaviour": "Comportement de l'utilisateur",
          "Register As": "S'inscrire en tant que",
          "Play Video": "Lire la vidéo",
          "Join Now": "Adhérer maintenant",
          "Join us to enjoy quality driving education": "Rejoignez-nous pour profiter d'une formation à la conduite de qualité",
          "The Revolutionary platform for true Driving education freelancers.":
            "La plateforme révolutionnaire pour les vrais indépendants de l'éducation à la conduite.",
          "Don’t have an account?": "Vous n'avez pas de compte ?",
          "I read and agree to": "J'ai lu et j'accepte",
          "Login to your account": "Connectez-vous à votre compte",
          "Unable to delete old availabilities.": "Impossible de supprimer les anciennes disponibilités.",
          // "Souhaitez-vous supprimer cette disponibilité?":
          //   "Would you like to remove this availability?",
          "Student Identity": "Mon identité",
          // "Vehicle Details": "Détails du véhicule",
          "No file chosen": "Aucun fichier sélectionné",
          "Data Submitted Successfully": "Informations Transmises avec Succès",
          "Search...": "Recherche...",
          Options: "Paramètres",
          options: "paramètres",
          "Book Me": "Réserves-moi",
          Languages: "Langues",
          Language: "Langue",
          Available: "Disponible",
          Meetup: "Point de rendez-vous",
          Automatic: "Automatique",
          // Manual: "Manuel",
          "Vehicles not Found": "Véhicule(s) non Trouvé(s) ",
          "Recent Chats": "CHATS RÉCENTS",
          "Permispop Chat": "Tes Chat",
          "Highway Code Date": "Date D'Obtention Du Code  ",
          "Give Feedback": "Donnez votre avis",
          "Rating Reminder": "COMMENTARIES",
          "Send Rating": "Envoyer",
          Home: "Accueil",
          Services: "Nos Services",
          "About Us": "A propos",
          Testimonials: "Avis",
          "FAQ'S": "FAQ",
          Packages: "Forfaits",
          "Contact Us": "Contacte-nous",
          Contact: "Contacte -",
          Us: "nous",
          Login: "Se connecter",
          "Register now": "S'inscrire ",
          Name: "Nom Prénom",
          "Your Message": "Votre message",
          "revolutionary-platform": "La plateforme pensée par les enseignants pour tous les professionnels du permis",
          "Join-us-text": "Rejoins-nous pour profiter d'un enseignement de la conduite de qualité.",
          "Student Registeration": "S'inscrire  en tant qu'élève",
          "First Name": "Prénom",
          Surname: "Nom",
          "Date of Birth": "Date de naissance",
          "Place of Birth": "Lieu de naissance",
          Gender: "Sexe",
          Male: "Homme",
          male: "Homme",
          Female: "Femme",
          female: "Femme",
          "Upload front of your ID Card/Passport/Residence permit": "Télécharge le recto de ta CNI/Passeport/Permis de séjour",
          "Upload back of your ID Card/Passport/Residence permit": "Télécharge le verso de ta CNI/Passeport/Permis de séjour",
          Address: "Adresse",
          City: "Ville",
          "Zip Code": "Code Postal",
          Complement: "Complément d'adresse",
          Phone: "Téléphone",
          "Email (2nd email)": "Email (2ème email)",
          "Phone (2nd phone)": "Téléphone (2ème téléphone)",
          Email: "E-mail",
          Password: "Mot de passe",
          "Remember me": "Souviens-toi de moi",
          "Forget Password": "Mot de passe oublié",
          password: "Mot de passe",
          "Confirm Password": "Confirme votre mot de passe",
          "Estimated number of hours you want to take": "Combien d'heures de conduite penses-tu prendre?",
          "NEPH Number": "Numéro NEPH",
          "Highway code obtained": "Code obtenu",
          "Click me if you had": "Clique ici si t'as obtenu votre code",
          "Terms & Conditions Applied": "J'accepte les termes et conditions appliqués",
          "Terms & Conditions": "Termes et conditions",
          "BACK TO WEBSITE": "Retour au site",
          "Privacy Policy": "Politique de confidentialité",
          "I Agreed": "J'accepte",
          Register: "S'inscrire ",
          "login-page": "Connexion",
          "Must be digits": "Doit être des chiffres",
          "Manager should be at least 23 years old": "Le manager ne peut pas avoir moins de 23 ans",
          "Manager Identity": "Identité du manager",
          "enter-email-pass": "Entre ton email et ton mot de passe pour te connecter à PermisPop",
          "profile-update-request": "Demande de mise à jour de mon profil",
          "select-option": "Sélectionne les informations à mettre à jour",
          reason: "Raison",
          "My Profile": "Mon profil",
          "Pending Requests": "Demande en attente",
          "No Pending Request": "Aucune reservation",
          History: "Mon historique",
          "no-history": "Aucun historique",
          Bookings: "Mes réservations",
          "Find an Instructor": "Rechercher un enseignant",
          "Find Instructor on Map": "Rechercher un enseignant sur la carte",
          Dispute: "Mes litiges",
          Disputes: "Litiges",
          "no-dispute": "Aucun litige",
          "My procedures": "Mes démarches",
          Questions: "Mes questions",
          "Insurance Detail": "Détail de l'assurance",
          "My Learning": "Mon apprentissage",
          "My Availability": "Mes disponibilités",
          Instructor: "Instructeur ",
          Instructors: "Enseignants de cette auto-école",
          "Book now": "Réserver maintenant",
          "Book with School": "Réserver avec l'auto-école",
          "Your Position": "Ta position",
          "Instructor Registeration": "S'inscrire  en tant qu'enseignant",
          StudentForm: "Formulaire d'inscription d'élève",
          "Give Dispute": "Ouvrir un litige",
          // document page
          "Delivery Place": "LIEU DE DÉLIVRANCE",
          require1vehicleError: "Nécessite au moins un véhicule",
          "16yearAgeError": "Vous devez avoir 16 ans.",
          "20yearAgeError": "Vous devez avoir 20 ans.",
          "23yearAgeError": "Vous devez avoir 23 ans.",
          // ----------------------------------------------------Registeration Instuctor-----------------
          Number: "N° d'agrément",
          "Date of Obtaining": "Date d'obtention",
          "Scan and Upload your Driving License": "Télécharge ton permis de conduire (recto/verso)",
          "Place of Delivery": "Lieu de délivrance",
          "Validity Date": "Date de validité",
          "Upload Scaned Green Card": "Télécharger la carte verte numérisée",
          "Do you have other licenses obtained?": "As-tu obtenu d'autres permis?",
          "License For?": "Licence pour?",
          "Spoken Language": "Langues parlées",
          "License Type?": "Type de licence?",
          // license type
          "Motorcycle license": "Permis moto",
          "Heavy car or quadricycle license": "Permis B (voiture ou quadricycle lourd)",
          "Licenses for the transportation of goods and people": "Permis pour le transport de marchandises et de personnes",
          // sub options
          "License A1 (125 cm3)": "Permis A1 (permis 125 cm3)",
          "License A2 (35 kw -)": "Permis A2 (- de 35 kw)",
          "License A (35 kw +)": "Permis A (+ de 35 kw)",
          // b sub options

          "License B (car or van)": "Permis B : voiture ou camionnette",
          "License B1 (heavy motor quadricycle)": "Permis B1 : quadricycle lourd à moteur",
          "License BE (car + trailer over 750 kg)": "Permis BE : voiture + remorque de plus de 750 kg",
          // c sub options

          "License C (7,5 t +)": "Permis C (+ de 7,5 t)",
          "License CE (7,5 t + with trailer)": "Permis CE (+ de 7,5 t avec remorque)",
          "License C1 (between 3,5 t and 7,5 t)": "Permis C1 (entre 3,5 t et 7,5 t)",
          "License C1E (between 3.5 t and 7.5 t with trailer)": "Permis C1E (entre 3,5 t et 7,5 t avec remorque)",
          "License D (transport more than 8 passengers)": "Permis D (transport + de 8 passagers)",
          "License DE (transport more than 8 passengers with trailer)": "Permis DE (transport + 8 passagers avec remorque)",
          "License D1 (transport of 16 passengers maximum)": "Permis D1 (transport de 16 passagers maximum)",
          "License D1E (transport of 16 passengers maximum with trailer)":
            "Permis D1E (transport de 16 passagers maximum avec remorque)",
          "Authorization to Exercise": "Autorisation d'exercer",
          "Date of the authorization to exercise": "Date de délivrance de l'autorisation d'exercer",
          "Issuing Authority": "Délivré par",
          "Expiration date": "Date d'expiration",
          "Expiration Date": "Date d'expiration",
          "Scan and upload your authorization": "Télécharge ton autorisation d'exercer",
          Business: "Entreprise",
          "SIrel Number": "N° de SIRET",
          "Upload your kbls": "Télécharge ton KBIS",
          "Upload your Kbls": "Télécharge ton KBIS",
          "Upload your KBLS": "Télécharge ton KBIS",
          "Insurance Company": "Compagnie d'assurance",
          Insurer: "Assurance",
          "Police Number": "N° de Police",
          "Upload your RC Pro": "Télécharge ton assurance RC Pro",
          "Expiration date of your Insurance": "Date d'expiration de ton assurance RC Pro",
          "Expiration date of your insurance": "Date d'expiration de ton assurance RC Pro",
          "Vehicle Insurance": "Assurance de ton/tes véhicule(s)",
          "Upload your Green Card": "Télécharge l'assurance de ton/tes véhicule(s)",
          "Start Date": "Date de début",
          "End Date": "Date de fin",
          "Insurance of the driving school": "Assurance de l'auto-école",
          "Vehicles Details": "Informations de ton véhicule",
          "Date of the 1st Registration": "Date de 1ère immatriculation",
          Brand: "Marque",
          // "Upload Image": "Télécharge une photo de ton véhicule",
          "Upload Image": "Téléchargez une photo de votre véhicule",
          "Upload Images": "Téléchargez une photo de votre véhicule",
          Model: "Modèle",
          Color: "Couleur",
          "Upload Vehicle Registration Certificate": "Télécharge ta carte grise (recto/verso)",
          Gearbox: "Boîte de vitesse",
          "Vehicle Image": "PHOTO DU VÉHICULE",
          "Register Certificate": "CERTIFICAT D'Enregistrer",
          "Green Card": "CERTIFICAT D'ASSURANCE",
          // "Technical control":"Contrôle technique",
          "Date of the technical control": "Date du dernier contrôle technique",
          "Scan and upload your CT": "Télécharge ton contrôle technique",
          "Scan and Upload your CT": "Télécharge ton contrôle technique",
          "Driving School Registeration": "S'inscrire  en tant qu'auto-école",
          "Rental Car Registeration": "S'inscrire en tant que loueur double commande",
          "View Booking": "Voir la réservation",
          Accept: "Accepter",
          Decline: "Décliner",
          Tradename: "Raison Sociale",
          "Company Name": "Nom de la société",
          Approval: "Agrément",
          // "Number":"Numéro de l'agrément",
          // "Issuing Authority":""
          "Upload your Certification": "Téléchargez votre agrément",
          "Date of Approval": "Date de délivrance de l'agrément",
          // "Manager Identity": "Identité du manager",
          Vehicles: "Véhicule(s)",
          "Maximum 2 and minimum 1 Vehicles can be add": "Tu peux ajouter jusqu'à 2 véhicules",
          "Maximum 2 Instructor can be add": "Un maximum de 2 enseignants peuvent être ajoutés",
          // "Vehicles Details": "Détails du véhicule",
          Employee: "Employé",
          "External provider": "Indépendant",
          "External Provider": "Indépendant",
          Energy: "Carburant",
          Manual: "Manuelle",
          Petrol: "Essence",
          Electric: "Electrique",
          "Type of contract with the driving school": "Type de contrat avec l'auto-école",
          Next: "Suivant",
          Back: "Précédent",
          greetings: "Salut",
          no_chat_title: "Aucun chat sélectionné",
          no_chat_desc: "Veuillez sélectionner n'importe quel chat pour commencer la messagerie",
          // -----------------------steps
          Identity: "Identité",
          "Identity Of Instructor": "Identité de l'enseignant",
          Details: "Détails",
          Insurance: "Assurance",
          Vehicle: "Véhicule",
          Manager: "Directeur",
          Done: "Terminé",
          Welcome: "Bienvenue",
          // --------------------------------
          "Get Register": "S'inscrire",
          Registeration: " - Formulaire d'inscription",
          Student: "Élève",
          "Driving School": "Auto-école",
          "Driving School Instructor": "Enseignant d'auto-école",
          DSInstructor: "Enseignant d'auto-école",
          // "Instructor Registeration": "S'inscrire  en tant qu'enseignant",
          // "Driving School Registeration": "S'inscrire  en tant qu'auto-école",
          "Instructor Identity": "Mon identité",
          "Company Identity": "Informations de ma société",
          "Driver's License": "Permis de conduire",
          instructorAgeError: "L'instructeur doit être âgé de 20 ans.",
          //-------------------------------------------------text paragraph
          "Student-Reg-para1":
            "Vous êtes là pour vous inscrire sur PermisPop en tant qu'élève ? Pas de problème! L'inscription se fait en une seule étape!\nVous êtes posé OKLM avec vos amis et l'un d'entre eux vient de vous parler de PermisPop ? Vous avez vu notre pub ou entendu parler de nous mais vous êtes en cours ou à l'extérieur ? Ne vous en allez pas!\nVous pouvez fournir tous vos documents et votre numéro NEPH après validation de votre profil ! C'est génial non ? Alors c'est parti, remplissez le formulaire et validez-le !",
          "Instructor-Reg-para1":
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          "DrivingSchool-Reg-para1":
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          "Reg-para2":
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          "Login-para": "",
          "Services-main-tag":
            "La plateforme de mise en relation PermisPop a été pensée par des enseignants pour tous les professionnels du permis dont les principaux services proposés sont les suivants.",
          "Behaviour-text": "Enseignant véhiculé ou non\nAuto-école\nLoueur double commandes",
          "bestTimeTrain-text": "Réservation\nGestion de planning\nGestion des disponibilités",
          "trainAllAges-text":
            "Heure facturable à 32€ max\nForfait à XX€ pour les élèves pour accéder à PermisPop\nForfait gratuit pour les auto-école et enseignants indépendants",
          "individualApproach-text": "Élève d'auto-école\nCandidat libre\nPerfectionnement de la conduite\nRemise à niveau",
          "calmIns-text":
            "Temps d’attente pour des heures de conduite\nIndisponibilité des enseignants\nSuivi difficile du planning\nAnnulation difficile des heures",
          "duplicatePedals-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "allDoc-text": "De nombreux partenaires\nPermis accéléré\nPossibilité d'obtenir une date d'examen rapidement",
          "bestClass-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "bestInstructor-text":
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti aliquid vero harum rerum voluptates, ab, ullam",
          "how-long-is-road-tes":
            "PermisPop est une plateforme en ligne de mise en relation entre élèves, auto-écoles et enseignants indépendants.\n\nNous proposons une plateforme en ligne complète pour faire accompagner les élèves jusqu'aux épreuves du permis de conduire par les meilleurs enseignants de la conduite diplômés d'Etat.\n\nTout est mis en place pour permettre à nos enseignants de la conduite de préparer leurs élèves à l'épreuve pratique du permis de conduire et faire d'eux des conducteurs sûrs et accomplis tout en proposant des taux horaires à des prix très attractifs!",
          "Faq-text": "Conseils et réponses de l'équipe PermisPop",
          "contact-text":
            "Besoin de nous contacter ? Tu peux remplir notre formulaire en ligne pour envoyer un e-mail à notre service clientèle. Nous te répondrons dans un délai de 2 jours ouvrables après la réception de ton e-mail.",

          // -------------------------------------------------------------------
          "Best Instructor": "Meilleurs enseignants",
          "Best Class Rooms": "meilleures salles de classe",
          "All Documents": "Tous les documents",
          "Duplicates Pedals": "Doubles commandes",
          "Calm Instructors": "Instructeurs calmes",
          "Individual Approach": "Pour tous les élèves",
          "We Train All Ages": "Nous formons tous les âges",
          "Our Services": "Plateforme complète",
          "Users Behavior": "Pour tous les professionnels",
          "SEND MESSAGE": "Envoyer le message",
          "Please give reasons for rating low": "Veuillez donner les raisons de la note faible",
          Late: "En retard",
          "How long is the road test appointment?": "PermisPop, comment ça fonctionne ?",
          General: "Général",
          "Our Solutions": "Nos solutions",
          "Our Experience": "Notre expérience",
          "Cutomer Service": "Service Clients",
          "Where we are": "Où sommes-nous",
          "Contact Info": "Informations de contact",
          What: "Que",
          We: "Faisons",
          Do: "Nous",
          Asked: "A demander",
          Frequently: "Fréquemment",
          "Customer Service": "Service Clients",
          InsuranceCompanyRegister: "Assurance Professionnelle",

          // ------------dashboard Translation ---------------------------------
          "Update Profile": "Mettre à jour le profil",
          Search: "Rechercher",
          pending: "A venir",
          Complete: "Complété",
          Completed: "Compléter",
          "In Progress": "En Cours",

          Edit: "Modifier",
          Delete: "Supprimer",
          Detach: "Détacher",
          "Add Instructor": "Ajouter un instructeur",
          Closed: "Terminé",
          Declined: "Diminué",
          // Dispute: "En litige",
          "Raise a dispute": "Remonter un problème",
          "Late Delivery": "Problème de timing",
          "Payment Issue": "Problème de paiement",
          Harassment: "Harcèlement",
          "Abusing language (Behaviour)": "Problème d'attitude",
          Behaviour: "Problème d'attitude",
          "Abusive Behaviour": "Agression Physique",
          "Lesson Content": "Contenu de la leçon",
          "Add Other Reason": "Ajouter une autre raison",
          "Add a comment": "Ajouter un commentaire",
          "Abusing-language": "Agression verbale",
          "Abusing language": "Agression verbale",
          // Other: "Autre",
          Comments: "Commentaires",
          Comment: "Commenter",
          "Select Chapters": "Sélectionnez les chapitres",
          Close: "Annuler",
          Submit: "Envoyer",
          "submit-question": "Ajouter",
          "Pick a Time": "Heure de début",
          "Make an Appointment For Student": "Réserver mes leçons",
          Filter: "Filtrer",
          Reset: "Réinitialiser",
          "Add Date Range Filter": "Sélectionne une plage de dates",
          "View Data": "Afficher les données",
          Role: "Rôle",
          Range: "Intervalle",
          All: "Tout",
          Apply: "Filtrer",
          Independent: "Indépendant",
          "Your Disputes": "Vos litiges",
          "No Dispute Found": "Aucun litige trouvé",
          "My Learning Booklet": "Mon livret d'apprentissage",
          Acquired: "Acquis",
          "I-Master": "Compétence 1 - Maîtriser",
          "II-Apprehend": "Compétence 2 - Appréhender",
          "III-Driving": "Compétence 3 - Circuler",
          "IV-Practive": "Compétence 4 - Pratiquer",
          Wording: "Compétence",
          "Student Learning Booklet": "Livret d'apprentissage de l'élève",
          // my learing lesson desciprtion\
          myLearingLessonDescription1: "Maîtriser le maniement du véhicule dans un trafic faible ou nul.",
          myLearingLessonDescription2: "Appréhender la route et circuler dans des conditions normales.",
          myLearingLessonDescription3: "Circuler dans des conditions difficiles et partager la route avec les autres usagers.",
          myLearingLessonDescription4: "Pratiquer une conduite autonome, sûre et économique.",
          // end of my learing lesson description
          "Legal Information": "Information légale",
          "*Click on text to open popup details.": "*Cliquez sur le texte pour voir les détails",
          "Why better learn?": "Pourquoi mieux apprendre?",
          "What is the use of your learning booklet?": "Quelle est l'utilité de ton livret d'apprentissage?",
          "What training program?": "Quel programme de formation?",
          "How are your progress assessed?": "Comment tes progrès sont-ils évalués?",
          "How is the exam?": "Comment se passe l'examen?",
          "Add Availablity": "Ajouter une disponibilité",
          "Vehicle Brand": "Marque du véhicule",
          "Vehile Model": "Modèle de véhicule",
          "Vehicle Color": "Couleur du véhicule",
          "Vehicle Engergy": "Énergie du véhicule",
          "Vehicle GearBox": "Boîte de vitesses du véhicule",
          Open: "Analyse en cours",
          Pending: "En attente",
          "Student Suspended": "Etudiant suspendu",
          "Intructor Suspended": "Enseignant suspendu",
          "Booking Detail": "Détail de la réservation",
          Date: "Date",
          "Start Hours": "Heure de début",
          "End Hours": "Heure de fin",
          "Start Minutes": "Minutes de départ",
          "End Minutes": "Minutes de fin",
          "Vehicle Select": "Sélection du véhicule",
          "Select Vehicle": "Sélectionne un véhicule",
          // Meetup: "Point de rencontre",
          "Picked DateTime": "Heure de début",

          "No Meetup Point. Add from you profile section": "Pas de point de rendez-vous renseigné. Ajoutez-le dans votre profil",
          "Add Meetup Point": "Ajouter un point de rendez-vous",
          Confirm: "Ajouter",
          Duration: "Durée",
          "Meetup Point": "Point de rencontre",
          "Select the options": "Sélectionnez les options",
          "Make Booking": "Faire une réservation",
          Reason: "Raison",
          "Booking Details": "Détails de réservation",
          "Add Meetup Location": "Ajouter un emplacement Meetup",
          "Profile Update Request": "Demande de mise à jour de profil",

          "User Info": "Informations utilisateur",
          "Booking Date": "Date de réservation",
          "Booking Time": "Heure de réservation",
          "License Number": "Numéro de licence",
          "Obtain Date": "Date d'obtention",
          "Driving License": "Permis de conduire",
          "Authorization Date": "DATE D'AUTORISATION",
          Sirat: "Kbis",
          "Auth Certificate": "AUTORISATION D'EXERCICE",
          "Exersice Authorization": "Autorisation d'exercice",
          "Expiry Date": "Date d'expiration",
          "Vehicle Detail": "Détails de votre/tes véhicule(s)",
          "Registration Date": "Date d'inscription",
          "Business Store": "Ma société",
          "Technical Control": "Contrôle technique",
          "Date of Technical Control": "Date du contrôle technique",
          "Add Question": "Ajouter une question",
          "No Question Found": "Aucune question trouvée",
          "Edit Question": "Modifier la question",
          "Number Plate": "Plaque d'immatriculation",
          "Must upload file": "Ce fichier est obligatoire",
          "Expiry date should be future date.": "La date d'expiration doit être une date ultérieure.",
          Add: "Ajouter",
          Active: "Actif",
          "deactive-title": "Compte désactivé",
          "deactive-message":
            "Ton compte a été désactivé par l'administrateur en raison de certaines conditions non respectées.\nContacte l'administrateur pour plus d'informations.\nContact",
          "non-verfiy-title": "Cette zone est accessible uniquement aux utilisateurs vérifiés",
          "non-verify-message": "Tu dois télécharger les documents manquants pour accéder à cette fonctionnalité.",
          "Upload documents": "Télécharger des documents",
          "member-title": "Uniquement accès Memeber",
          "member-message": "Pour accéder à cette page s'il vous plaît en adhérant",
          "No History Found": "Aucun historique trouvé",
          "No Pending Request Found": "Aucune demande en attente trouvée",
          // my learing
          myLearningLeagalInfoMessage: "*Clique sur le texte pour voir les détails",
          closeBtnMylearning: "Fermer",
          "No Comments Added": "Pas de commentaire ajouté",
          // booking info desc
          "Instructor's Availability": "Disponibilité de l'instructeur",
          "Instructor's Bookings": "Réservations de l'instructeur",
          "Driving School Bookings": "Réservations d'écoles de conduite",
          Booking: "Réservations",
          Availability: "Disponibilité",
          DrivingSchool: "Auto-école",
          DrivingSchoolInstructor: "Enseignant d'auto-école",
          pastDateError: "Ne doit pas être une date dans le passé",

          // -------------------------------------------------------------------
          // ----------------Registration errors--------------------------------
          "Should Not be empty": "Ne peut pas être vide",
          "Address field should not be empty": "L'adresse ne peut pas être vide",
          "Email should be valid": "L'e-mail doit être valide",
          "Email alread exist": "Les e-mails existent déjà",
          "Phone must be 10 digits": "Le téléphone doit être composé de 10 chiffres",
          "NEPH number must be 12 digits": "Le n°NEPH doit être de 12 chiffres",
          "NEPH number must be numeric only": "Le n°NEPH doit être numérique uniquement",
          "An account has already been created with this email": "Un compte a déjà été créé avec cet e-mail",
          "Must be numeric": "Ce champ doit être uniquement numérique",
          "Password must contain only alphanumeric, special characters and must have a minimum length of 8":
            "Le mot de passe ne doit contenir que des caractères alphanumériques spéciaux et doit avoir une longueur minimale de 8",
          "Password did not match": "Le mot de passe ne correspond pas",
          "neph-length-error": "la longueur du néph doit être de 12 chiffres.",
          "neph-numeric-error": "neph doit être bénumérique uniquement.",
          "should select this field to continue.": "devrait sélectionner ce champ pour continuer.",
          acceptManagerCondition: "Tu dois accepter les conditions générales.",
          "Obtain date can not be future date.": "Obtenir la date ne peut pas être une date future.",
          "Second phone should not be same": "Les téléphone ci-dessus ne doivent pas être identiques",
          "Second email should not be same": "Le e-mails ci-dessus ne doit pas être le même",
          "Above email should not be empty": "L'e-mail ci-dessus ne doit pas être vide",
          "Above phone should not be empty": "Le téléphone ci-dessus ne doit pas être vide",
          "You can not select future date": "Tu ne peux pas sélectionner une date dans le futur",
          futureDateRequiredError: "La date doit être la date future.",
          futureDateNotError: "Ne doit pas être une date dans le futur",
          "Should not be past date": "La date d'expiration ne doit pas être dans le passé",
          "Should not be future date": "Ne doit pas être une date dans le futur",
          pastExpireError: "La date d'expiration ne doit pas être antérieure.",
          kbis14digits: "Sirel doit comporter 14 chiffres.",
          "Vehicle Details": "Détails de ton/tes véhicule(s)",
          validityDateErrorInstructor: "La date de validité ne peut être inférieure à la date d'obtention.",
          "Date should not be future date.": "La date ne doit pas être une date future.",
          "Date should not be future date": "La date ne doit pas être une date future.",
          "You can't pick same date of Start date": "Tu ne peux pas choisir la même date que la date de début",
          "You can't pick higher date of Start date": "Tu ne peux pas choisir une date supérieure à la date de début",
          "i-agree-instructor-final":
            "J'ai accepté et je certifie sur l'honneur que les informations ci-dessus sont véridiques et exactes et que j'ai vérifié leur contenu",
          "Forgot Passowrd": "Mot de passe oublié",
          "valid-email-for-forgot-password": "Entre ton adresse e-mail valide pour recevoir tes mails",
          "Enter Password to update": "Entre le mot de passe à mettre à jour",
          "Postal Code": "Code postal",
          "I accept financing by CPF": "J'accepte le financement par CPF",
          "accept-ds-text-page1-title": "Accepter CPF",
          "accept-ds-text-page1": "J'accepte le financement par CPF",
          "Sign up": "S'inscrire",
          Loading: "Chargement",
          Our: "Nos",
          ServicesHome: "Services",
          "Our Price": "Notre prix",
          "Information to update": "Informations à mettre à jour",
          "footer-info-get-started":
            "Nos solutions de marketing social aident plus de 2500 entreprises à travers le monde à obtenir d'excellents résultats. Nous ne pouvons pas supporter la moyenne et nos clients non plus.",
          "GET STRATED": "COMMENCER",
          footerdesc:
            "La plateforme pensée par les enseignants pour tous les professionnels du permis.\nRejoins-nous pour profiter d'un enseignement de la conduite de qualité.",
          packagetitle:
            "Lorem Ipsum is simply text the printing and typesetting standard industry. Onec Consequat sapien ut cursus rhoncus. Nullam dui mi, vulputate ac metus.",
          "i-certify-neph": "Je certifie sur l'honneur que les informations ci-dessus sont vraies et j'ai obtenu le code.",
          "neph-confirm-error": "Tu dois sélectionner ce champ pour continuer.",
          "auto-complete-error": "Sélectionne les langues que tu sais parler",
          "which platform(s)?": "Quelle(s) plateforme(s)?",
          "Which platform(s)?": "Quelle(s) plateforme(s)?",
          "How much time will you give to the students of our platform?(hr/month)":
            "Combien de temps donneras-tu aux étudiants de notre plateforme?(heure/mois)",
          "Are you working on another platform?": "Travailles-tu sur d'autre(s) plateforme(s)?",
          "seperated by": "séparé par",

          // table headers
          "Booked With": "Réservé avec",
          // Date: "Date",
          "Start Time": "Heure de début",
          Status: "Statut",
          Action: "Action",
          action: "action",
          Actions: "Actions",
          "Show Booking": "Afficher la réservation",

          // my learing Items translation
          // Compétence 1
          myLearningLesson1:
            "Connaître les principaux organes et commandes du véhicule, effectuer des vérifications intérieures et extérieures.",
          myLearningLesson2: "Entrer, s’installer au poste de conduite et en sortir.",
          myLearningLesson3: "Tenir, tourner le volant et maintenir la trajectoire.",
          myLearningLesson4: "Démarrer et s’arrêter.",
          myLearningLesson5: "Doser l’accélération et le freinage à diverses allures.",
          myLearningLesson6: "Utiliser la boîte de vitesses.",
          myLearningLesson7: "Diriger la voiture en avant en ligne droite et en courbe en adaptant allure et trajectoire.",
          myLearningLesson8: "Regarder autour de soi et avertir.",
          myLearningLesson9: "Effectuer une marche arrière et un demi-tour en sécurité.",
          // Compétence 2
          myLearningLesson10: "Entrer, s’installer au poste de conduite et en sortir.",
          myLearningLesson11: "Rechercher la signalisation, les indices utiles et en tenir compte.",
          myLearningLesson12: "Positionner le véhicule sur la chaussée et choisir la voie de circulation.",
          myLearningLesson13: "Adapter l’allure aux situations.",
          myLearningLesson14: "Tourner à droite et à gauche en agglomération.",
          myLearningLesson15: "Détecter, identifier et franchir les intersections suivant le régime de priorité.",
          myLearningLesson16: "Franchir les ronds-points et les carrefours à sens giratoire.",
          myLearningLesson17: "S’arrêter et stationner en épi, en bataille et en créneau.",
          // Compétence 3
          myLearningLesson18: "Evaluer et maintenir les distances de sécurité.",
          myLearningLesson19: "Croiser, dépasser, être dépassé.",
          myLearningLesson20: "Passer des virages et conduire en déclivité.",
          myLearningLesson21:
            "Connaître les caractéristiques des autres usagers et savoir se comporter à leur égard avec respect et courtoisie.",
          myLearningLesson22: "S’insérer, circuler et sortir d’une voie rapide.",
          myLearningLesson23: "Conduire dans une file de véhicules et dans une circulation dense.",
          myLearningLesson24: "Conduire quand l’adhérence et la visibilité sont réduites.",
          // Compétence 4
          myLearningLesson25: "Suivre un itinéraire de manière autonome.",
          myLearningLesson26: "Préparer et effectuer un voyage « longue distance » en autonomie.",
          myLearningLesson27: "Connaître les principaux facteurs de risque au volant et les recommandations à appliquer.",
          myLearningLesson28: "Connaître les comportements à adopter en cas d’accident : protéger, alerter, secourir.",
          myLearningLesson29:
            "Faire l’expérience des aides à la conduite du véhicule (régulateur, limiteur de vitesse, ABS,aides à la navigation...).",
          myLearningLesson30: "Avoir des notions sur l’entretien, le dépannage et les situations d’urgence.",
          myLearningLesson31: "Pratiquer l’écoconduite.",
          "Instructor deleted successfully": "Formateur supprimé avec succès",
          "Detach successfully": "Détacher avec succès",
          Add: "Ajouter",
          submit: "nous faire parvenir",
          "Comment added successfully": "Commentaire ajouté avec succès",
          "Rating cannot be zero": "La note ne peut pas être nulle",
          "Reminder cannot be empty": "Le rappel ne peut pas être vide",
          "Rating submitted successfully": "Évaluation soumise avec succès",
          "Profile Image Changed successfully!": "Image du profil modifiée avec succès !",
          "Must select a vehicle to book!": "Doit sélectionner un véhicule pour réserver!",
          "Must select a meetup point!": "Vous devez sélectionner un point de rencontre !",
          "Start and End date cannot be same!": "Les dates de début et de fin ne peuvent pas être identiques !",
          "Availabilty already exists for this time slot!": "Des disponibilités existent déjà pour ce créneau horaire!",
          "Please enter a description!": "Veuillez entrer une description !",
          "Meetup points added successfully": "Points Meetup ajoutés avec succès",
          "Detach successfully": "Détacher avec succès",
          "Instrcutor added successfully": "Instructeur ajouté avec succès",
          "Request is created for profile update": "La demande est créée pour la mise à jour du profil",
          "Description cannot be empty": "La description ne peut pas être vide",
          "New password does not match Confirm password": "Le nouveau mot de passe ne correspond pas Confirmer le mot de passe",
          "Cannot accept empty fields": "Impossible d'accepter les champs vides",
          " Comments": "commentaires",
          "Comment is Empty": "Le commentaire est vide",
          "Not Found": "Pas trouvé",
          "I - Master": "I- Maître",
          "II - Apprehend": "II - Appréhender",
          "III - Driving": "III - Conduite",
          "IV - Practice": "IV - Pratique",
          "Practice the handling of the vehicle in light or no traffic":
            "Pratiquer la conduite du véhicule dans un trafic léger ou nul",
          "Apprehend the handling of the vehicle in light or no traffic":
            "Appréhender le comportement du véhicule en trafic faible ou nul",
          "Drive the handling of the vehicle in light or no traffic":
            "Conduire la conduite du véhicule dans un trafic léger ou nul",
          "Master the handling of the vehicle in light or no traffic":
            "Maîtriser la tenue de route du véhicule en circulation faible ou nulle",
          "Learn Car Driving with Permispop": "Apprendre la conduite automobile avec Permispop",
          "Cannot select this event": "Impossible de sélectionner cet événement",
          "Can not select past event": "Impossible de sélectionner un événement passé",
          "Booking Deleted successfully!": "Réservation supprimée avec succès !",
          "Booking time cannot exceed availability end time!":
            "L'heure de réservation ne peut pas dépasser l'heure de fin de disponibilité !",
          "Booking time cannot less then availability start time!":
            "L'heure de réservation ne peut pas être inférieure à l'heure de début de la disponibilité !",
          "Booking Added successfully!": "Réservation ajoutée avec succès !",
          "Unavailabilty Added for date": "Indisponibilité Ajouté pour date",
          "Personal Event Added for date": "Événement personnel ajouté pour date",
          "Late delivery": "Livraison tardive",
          "Payment issue": "Problème de paiement",
          Harassment: "Harcèlement",
          "Abusing Language": "Abus de langage",
          "Create Dispute": "Créer un litige",
          // REGISTRATION INPUTS AND ERROR
          "SIRET Number": "N° de SIRET",
          "The SIRET number must be 14 digits": "Le n° de SIRET doit comporter 14 chiffres",
          "Upload Scanned Green Card": "Télécharger la carte verte numérisée",
          "Driver's License Number": "N° de permis de conduire",

          // Login page modals
          "Please input your E-mail!": "Renseignez votre adresse mail",
          "Please input your password!": "Renseignez votre mot de passe",
          "Choose your profile to register": "Choisissez votre profil pour vous inscrire :",
          "Log in": "Se connecter",
          "Forgot password": "Mot de passe oublié",
          Or: "Ou",
          " register now!": " Inscrivez-vous maintenant!",
          Professional: "Professionnel",
          " Registration": "Inscription",
          "REGISTRATION AS A STUDENT": "INSCRIPTION EN TANT QU'ÉLÈVE",
          "Rental Car Registration": "Inscription loueur de voitures double commande",
          "REGISTRATION AS A AUTO SCHOOL": "INSCRIPTION EN TANT QU'AUTO ÉCOLE",
          "REGISTRATION AS A TEACHER": "INSCRIPTION EN TANT QU'ENSEIGNANT",
          "REGISTRATION AS A DUAL ORDER CAR RENTAL AGENCY":
            "INSCRIPTION EN TANT QU'AGENCE DE LOCATION DE VOITURE DOUBLE COMMANDE",

          // Calendar Modals
          UnAvailability: "Indisponibilité",
          Personal: "Personnel",
          "Add Event": "Ajouter un évènement",
          "Add UnAvailability": "Ajouter une indisponibilité",
          "Add Personal Event": "Ajouter un rendez-vous personnel",
          "Add Availability": "Ajouter une disponibilité",
          "Delete Availability": "Supprimer la disponibilité",
          "Select Hours": "Sélection de l'heure",
          "Select Minutes": "Sélection des minutes",
          "Enter valid Time Range": "Entre une plage de temps valide",
          "Vehicle field Cannot be empty": "Le véhicule ne peut pas être vide",
          "Meet up point cannot be blank. Add it from you profile page":
            "Le point de rendez-vous ne peut pas être vide. Ajoutez-le depuis votre page de profil",
          "Select Instructor": "Sélectionnes l'enseignant",
          "Date field cannot be empty": "La date ne peut pas être vide",
          "Start date cannot be after End date!": "La date de début ne peut pas être postérieure à la date de fin !",
          "Cannot create a availability for 30 minutes!": "Impossible de créer une disponibilité pendant 30 minutes !",
          "Availabilty Added for date": "Disponibilité Ajouté pour date",

          // Registration fields
          "Upload your Driving License Front Side": "Téléchargez le recto de votre permis de conduire",
          "Upload your driving license front side": "Téléchargez le recto de votre permis de conduire",
          "Upload your Driving License Back Side": "Téléchargez le verso de votre permis de conduire",
          "Upload your driving license back side": "Téléchargez le verso de votre permis de conduire",
          "Upload your authorization front side": "Téléchargez le recto de votre autorisation d'exercise",
          "Upload your authorization back side": "Téléchargez le verso de votre autorisation d'exercise",
          "Upload your Authorization Front Side": "Téléchargez le recto de votre autorisation d'exercise",
          "Upload your Authorization Back Side": "Téléchargez le verso de votre autorisation d'exercise",
          "Authorization Number": "Numéro d'autorisation d'exercice",
          "Duration should not be less than 1 hour": "La durée ne peut pas être inférieure à 1 heure",

          "You must accept the general conditions": "Vous devez accepter les CGU / CGV",
          "Select option": "Selection d'une option",
          "Select Brand": "Selection de la marque",
          "Select Model": "Selection du modèle",
          "Re-Book": "Re-réserver",
          GEARBOX: "BOÎTE DE VITESSES",
          GearBox: "Boîte de vitesses",
          "Do you want to delete booking of this vehicle?": "Voulez-vous supprimer la réservation de ce véhicule ?",
          Cancel: "Annuler",
          "Car Rental Agent": "Agent de location de voitures",
          "Rental Car Agent": "Agent de location de voitures",
          CarRentalAgent: "Agent de location de voitures",
          "View larger map": "Agrandir le plan",
          yes: "oui",

          "Change Password": "Changer de mot de passe",
          "Request Profile Update": "Demander la mise à jour de mon profil",
          "Update Vehicle": "Mettre à jour le véhicule",
          Profile: "Profil",

          "Would you like to remove this availability?": "Souhaitez-vous supprimer cette disponibilité?",
          "CPF Accepted": "CPF Accepté",

          "Reason-Information to update": "Raison ou informations à mettre à jour",
          "Your email or password is incorrect!": "Votre e-mail ou votre mot de passe est incorrect!",
          "'Email' is not a valid email": "L'email n'est pas valide",
          "Email is invalid!": "L'email n'est pas valide",
          "Enter the email address associated with your account and we'll send you a link to reset your password":
            "Entrez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe",
          "Return to sign in": "Retour pour se connecter",
          "Reset your password": "Réinitialiser mon mot de passe",
          "Update Query Request": "Demande de mise à jour",
          "select meetup": "Sélection du point de rendez-vous",
          "Select Meetup": "Sélection du point de rendez-vous",
          "search rating from": "Rechercher l'évaluation de",
          "upload image": "Télécharger une image",
          "Please input your email!": "Veuillez saisir votre email!",
          "Current Password": "Mot de passe actuel",
          "New Password": "nouveau mot de passe",
          "Must be minimum 8 characters": "doit être de minimum 8 caractères",
          "Please confirm your Password!": "Veuillez confirmer votre mot de passe!",
          "Please input your New Password!": "Veuillez saisir votre nouveau mot de passe!",
          "Please input your Current Password!": "Veuillez saisir votre mot de passe actuel!",
          "The two passwords that you entered do not match!": "Les deux mots de passe que vous avez entrés ne correspondent pas!",
          items: "Lignes",
          "Would you like to delete this availability?": "Souhaitez-vous supprimer cette disponibilité?",
          "Would you like to remove this Booking?": "Souhaitez-vous supprimer cette réservation ?",
          "Name Should not be empty": "Le nom ne peut pas être vide",
          "Email Should not be empty": "L'e-mail ne doit pas être vide",
          "Message Should not be empty": "Le message ne peut pas être vide",
          StudentComplete: "ÉlèveComplété",
          "My Calendar": "Mon calendrier",

          Yes: "Oui",
          No: "Non",

          Block: "Bloquer",
          Unblock: "Débloquer",
          Payment: "Paiement",
          offline: "hors ligne",
          online: "en ligne",

          "You blocked": "Vous êtes bloqué",

          "You are blocked by": "Vous avez été bloqué par",

          "Are you want to re-activate your account": "Voulez-vous réactiver votre compte",
          "Select Package": "Sélectionnez le forfait",
          "Best Time Training": "Meilleur temps d'entraînement",
          "Enter email": "Entrez votre e-mail",
          Manaager: "Gestionnaire",
          Vaction: "Vacances",
          // -------Added from list-------------
          "Your address cannot be empty": "Votre adresse ne peut pas être vide",
          "Excercice authorization number cannot be empty": "Votre numéro d'autorisation ne peut pas être vide",
          "Excercice authorization issuer cannot be empty": "Votre autorité de délivrance ne peut pas être vide",
          "Your driving license's number cannot be empty": "Votre numéro de permis de conduire ne peut pas être vide",
          "Your number plate cannot be empty": "Votre plaque d'immatriculation ne peut pas être vide",
          '"Number" cannot be empty': "Numéro ne peut pas être vide",
          '"Numéro" cannot be empty': "Numéro ne peut pas être vide",
          "Your company's name cannot be empty": "Le nom de votre société ne peut pas être vide",
          "My identity": "Mon identité",
          "My company's information": "Informations de ma société",
          "Driving license": "Permis de conduire",
          "The teacher must be 20 years old": "L'enseignant doit être âgé de 20 ans",
          "Best driving lessons": "Meilleurs cours de conduite",
        },
      },
    },
    fallbackLng: "fr",
    debug: false,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
